import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { MomentModule } from 'ngx-moment';
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TokenInterceptorService } from './token-interceptor.service'
import { NgxCaptchaModule } from 'ngx-captcha';

export const MY_MOMENT_FORMATS = {
  // parseInput: 'llll LT',
  //  fullPickerInput: 'llll',
  //  datePickerInput: 'llll',
  // timePickerInput: 'LT',
  // monthYearLabel: 'MMM YYYY',
  // dateA11yLabel: 'LLLL',
  // monthYearA11yLabel: 'MMMM YYYY',

  parseInput: 'YYYY-MM-DD',
  fullPickerInput: 'llll',
  datePickerInput: 'YYYY-MM-DD',
  // timePickerInput: 'hh:mm a',
  monthYearLabel: 'MMM-YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM-YYYY'


  // parseInput: 'llll , MMMM Do, YYYY',
  // fullPickerInput: 'llll , MMMM Do, YYYY hh:mm a',
  // datePickerInput: 'llll , MMMM Do, YYYY',
  // timePickerInput: 'hh:mm a',
  // monthYearLabel: 'MMMM YYYY',
  // dateA11yLabel: 'LL',
  // monthYearA11yLabel: 'MMMM YYYY'

};

import {

  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  // MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatBottomSheetModule,
  MAT_DIALOG_DATA,

} from '@angular/material';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';
import { AuthServiceConfig, FacebookLoginProvider, LoginOpt } from 'angularx-social-login';
import { ShareButtonModule } from '@ngx-share/button';

import { DataService } from './data.service';
import { AuthenticationService } from './authentication.service';
import { AppRoutes } from './app.routes';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoadingComponent } from './loading/loading.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { SelectTicketsComponent } from './select-tickets/select-tickets.component';
import { LoginComponent } from './login/login.component';
import { ViewImageComponent } from './view-image/view-image.component';
import { GetDirectionsComponent } from './get-directions/get-directions.component';
import { GetEmailComponent } from './get-email/get-email.component';
import { RegisterTicketsComponent } from './register-tickets/register-tickets.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PaymentScreenComponent } from './payment-screen/payment-screen.component';
import { GetPhoneNoComponent } from './get-phone-no/get-phone-no.component';
import { SharingDialogComponent } from './sharing-dialog/sharing-dialog.component';
import { SearchEventsComponent } from './search-events/search-events.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { MyticketsComponent } from './mytickets/mytickets.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppRoutingModule } from './/app-routing.module';
import { AppDashboardComponent } from './app-dashboard/app-dashboard.component';
import { SaleComponent } from './app-dashboard/sale/sale.component';
import { LeftMenuComponent } from './app-dashboard/left-menu/left-menu.component';
import { HeaderComponent } from './app-dashboard/header/header.component';
import { FooterComponent } from './app-dashboard/footer/footer.component';
import { EarningComponent } from './app-dashboard/earning/earning.component';
import { ContactusComponent } from './app-dashboard/contactus/contactus.component';
import { OverviewComponent } from './app-dashboard/overview/overview.component';

import { CheckinComponent } from './app-dashboard/checkin/checkin.component';

import { PayoutComponent } from './app-dashboard/payout/payout.component';
import { NeweventComponent } from './app-dashboard/newevent/newevent.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignupComponent } from './signup/signup.component';
import { LoginMobileComponent } from './login-mobile/login-mobile.component';
import { BsModalRef, CarouselModule, ModalModule } from 'ngx-bootstrap';
import { NgxPrintModule } from 'ngx-print';
import { BlockCopyPasteDirective } from 'src/core/directives/blockcopypaste.directive';
import { OnlyNumberDirective } from 'src/core/directives/numonly.directive';
import { TicketDownloader } from './ticket-downloader/ticket-downloader.component';

const fbLoginOptions: LoginOpt = {
  scope: 'email,user_birthday',
  return_scopes: true,
  enable_profile_selector: true
};

const config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("695805447534965", fbLoginOptions)  //apsession live
  },
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(
      '828870504295-am5su34dfn5g1bouf8fsg4b2b7gdclll.apps.googleusercontent.com'
    )
  }
]);

export function provideConfig() {
  return config;
}

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // override hammerjs default configuration
    'swipe': { direction: Hammer.DIRECTION_ALL }
  }
}

@NgModule({
  declarations: [

    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    LoadingComponent,
    EventDetailsComponent,
    SelectTicketsComponent,
    LoginComponent,
    ViewImageComponent,
    GetDirectionsComponent,
    GetEmailComponent,
    RegisterTicketsComponent,
    ForgotPasswordComponent,
    PaymentScreenComponent,
    GetPhoneNoComponent,
    SharingDialogComponent,
    SearchEventsComponent,
    DeleteConfirmationComponent,
    MyticketsComponent,
    DashboardComponent,
    AppDashboardComponent,
    SaleComponent,
    LeftMenuComponent,
    HeaderComponent,
    FooterComponent,
    EarningComponent,
    ContactusComponent,
    OverviewComponent,
    CheckinComponent,
    PayoutComponent,
    NeweventComponent,
    ResetPasswordComponent,
    SignupComponent,
    LoginMobileComponent,
    TicketDownloader,
    BlockCopyPasteDirective,
    OnlyNumberDirective

  ],
  imports: [
    ImageCropperModule,
    OwlMomentDateTimeModule,
    OwlDateTimeModule,
    MomentModule,
    GooglePlaceModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ShareButtonModule.forRoot(),
    MatDialogModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    // MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatBottomSheetModule,
    SocialLoginModule,
    NgxPrintModule,
    NgCircleProgressModule.forRoot({
    }),
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    RouterModule.forRoot(AppRoutes, { useHash: true, onSameUrlNavigation: "reload" ,scrollPositionRestoration:'enabled'}),
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBx_xvhQwRgWNiPAzxo3IL37qmgX1Ggepw'
    }),
    AgmDirectionModule,
    NgxCaptchaModule
  ],
  entryComponents: [
    LoadingComponent,
    SelectTicketsComponent,
    LoginComponent,
    ViewImageComponent,
    GetDirectionsComponent,
    GetEmailComponent,
    RegisterTicketsComponent,
    ForgotPasswordComponent,
    PaymentScreenComponent,
    GetPhoneNoComponent,
    SharingDialogComponent,
    SearchEventsComponent,
    DeleteConfirmationComponent,
    TicketDownloader
  ],
  providers: [TicketDownloader,BsModalRef,DataService, AuthenticationService, GoogleMapsAPIWrapper, {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }, {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
