/* ******************************************************************************
OptimumBrew Technology Pvt. Ltd.

Title:            Apsession v2
File:             Search Events TS
Since:            21 Sept, 2018
Author:           Umesh Patadiya
Email:            umeshpatadiya1995@gmail.com

****************************************************************************** */
import { Component, OnInit, NgZone, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog, MatDialogRef } from '@angular/material';
import { MouseEvent } from '@agm/core';
import * as moment from 'moment';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'app-search-events',
  templateUrl: './search-events.component.html',
  styleUrls: ['./search-events.component.css']
})
export class SearchEventsComponent implements OnInit {

  user_search: any;
  selected_country: any = {};
  event_details: any = {};
  disp_event_details: any = {};
  country_list: any = [];
  city_list: any = [];
  category_list: any = [];
  selected_city: any;
  selected_category: any;
  events_list: any = [];
  rec_events_list: any = [];
  display_events_list: any = [];
  total_events: any;
  error_message: any;
  loading: any;
  login_response: any = {};
  isLoggedIn: any = false;

  constructor(public dataService: DataService, private router: Router, zone: NgZone, public snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<SearchEventsComponent>) {
    this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
  }

  ngOnInit() {
    this.searchEventsByName(this.user_search);
  }

  searchEventsByName(user_search) {
    let tmp_city: any = "", tmp_category: any = "";
    let request_data = new FormData();
    request_data.append("eventname", user_search.trim());
    request_data.append("parish", tmp_city);
    request_data.append("genre", tmp_category);
    request_data.append("country", this.selected_country.country);

    this.dataService.postData("searchEventByName", request_data, {}).then((results: any) => {
      if (results.errorcode == 0) {
        this.events_list = results.result;
        this.total_events = this.events_list.length;
        this.display_events_list = [];
        this.events_list.forEach((element, i) => {
          element.date = moment(element.date).format("ddd, MMM DD - YYYY");
          /* if (i < 9) { */
          this.display_events_list.push(element);
          /* } */
        });
        this.loading.close();
      }
      else {
        this.display_events_list = [];
        this.events_list = [];
        this.total_events = this.events_list.length;
        this.error_message = results.status;
        this.showError(results.status, false);
        this.loading.close();
      }
    }, err => {
      console.log(err);
      this.loading.close();
      this.showError("Unable to connect with server, please try again.", false);
    });
  }

  openEventDetails(event_details) {
    this.dataService.setLocalStorageData("event_details", event_details);
    this.dialogRef.close({ "event_details": event_details });
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  showSuccess(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-success'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

}
