/* ******************************************************************************
OptimumBrew Technology Pvt. Ltd.

Title:            Get Email TS
Since:            21 Sept, 2018
Author:           Umesh Patadiya
Email:            umeshpatadiya1995@gmail.com

****************************************************************************** */
import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog, MatDialogRef } from '@angular/material';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'app-get-email',
  templateUrl: './get-email.component.html',
  styleUrls: ['./get-email.component.css']
})
export class GetEmailComponent implements OnInit {

  user_detail: any = {};
  loading: any;

  constructor(public dataService: DataService, public snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<GetEmailComponent>) {
  }

  ngOnInit() {
  }

  do_login(user_detail) {
    if (typeof user_detail.email == "undefined" || user_detail.email.trim() == "" || user_detail.email == null) {
      this.showError("Please enter email", false);
      return false;
    }
    else if (!this.dataService.validateEmail(user_detail.email)) {
      this.showError("Please enter a valid email", false);
      return false;
    }
    else {
      this.dialogRef.close(user_detail);
    }
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  showSuccess(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-success'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

}
