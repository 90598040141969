import { Component, OnInit, NgZone, ElementRef } from '@angular/core';
import { DataService } from '../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog, MatDialogRef } from '@angular/material';
import { LoginComponent } from '../login/login.component';
import { RegisterTicketsComponent } from '../register-tickets/register-tickets.component';

@Component({
  selector: 'app-select-tickets',
  templateUrl: './select-tickets.component.html',
  styleUrls: ['./select-tickets.component.css']
})
export class SelectTicketsComponent implements OnInit {

  event_details: any = {};
  checkout_val: any = 0;
  ticket_count: any = 0;
  login_response: any = {};
  selected_tickets: any = [];

  constructor(public dataService: DataService, public snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<SelectTicketsComponent>) {
  }

  ngOnInit() {
    let login_response = this.dataService.getLocalStorageData("login_response");

    console.log(login_response, "login_response==asdjflaksdlfkjaskljdflkjaslkdjfklajskldfjalkjfdlkjalksjfdlajsldfjasldf")

    this.event_details = this.dialogRef.componentInstance.event_details;
    console.log(this.event_details, "event_details==================");
    if (this.login_response) {
      this.getTicketsData();
    }
    else {
      let dialogRef = this.dialog.open(LoginComponent, {
        width: '600px',
        panelClass: 'login-container'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.login_response = result;
          this.getTicketsData();
        }
        else {
          this.dialogRef.close();
        }
      });
    }
  }

  getTicketsData() {
    this.event_details.tickets.forEach(element => {
      element.qtyArray = [];
      // this.event_details.tickets.push(element);
      element.qtyArray.push(element.Qty);
      for (let i = 0; i < element.QtyLimit; i++) {
        element.qtyArray.push(i + 1);
      }
      let otherFee = (parseFloat(this.event_details.buyer_ticket_fee_dollar_amount));
      element.fees = ((parseFloat(element.advancePrice) * (parseFloat(this.event_details.buyerTicketFeeAmountPercentage)) / 100) + otherFee).toFixed(2);
      element.advancePrice = parseFloat(element.advancePrice).toFixed(2);
    });

  }

  calculateTotal(event_details) {
    let tmp_vals = [];
    let qty_arr = [];
    let selected_tickets = [];
    event_details.tickets.forEach(element => {
      element.total_price = (element.Qty * element.advancePrice) + (element.Qty * element.fees)
    });
    event_details.tickets.forEach(element => {
      if (element.Qty > 0) {
        tmp_vals.push(element.total_price);
        qty_arr.push(element.Qty);
        /* selected_tickets.push(element); */
        for (let i = 0; i < element.Qty; i++) {
          selected_tickets.push(element);
        }
      }
    });
    this.selected_tickets = selected_tickets;
    this.ticket_count = qty_arr.reduce((a, b) => a + b, 0);
    let totalValue = tmp_vals.reduce((a, b) => a + b, 0);
    this.checkout_val = this.dataService.numberWithCommas(totalValue);
    this.dataService.setLocalStorageData("selected_tickets", this.selected_tickets);
  }


  registerTickets() {

    let login_response = this.dataService.getLocalStorageData("login_response");

    if (!login_response) {
      let dialogRef = this.dialog.open(LoginComponent, {
        width: '600px',
        panelClass: 'login-container'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.login_response = result;
          this.getTicketsData();
        }
        else {
          this.dialogRef.close();
        }
      });
    }
    else {

      if (this.ticket_count <= 0) {
        this.showError("Please select atleast one ticket", false);
        return false;
      }
      else {
        let unique_tickets: any = [];
        this.event_details.tickets.forEach(element => {
          if (element.Qty && element.Qty > 0) {
            unique_tickets.push(element);
          }
        });

        this.dataService.setLocalStorageData("unique_tickets", unique_tickets);
        let dialogRef = this.dialog.open(RegisterTicketsComponent, {
          width: '600px',
          panelClass: 'login-container'
        });
        dialogRef.componentInstance.event_details = this.event_details;
        this.dialogRef.close();
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
          }
          else {

          }
        });
      }
    }


  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  showSuccess(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-success'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

}
