import { Component, OnInit, NgZone, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';
import { MouseEvent } from '@agm/core';
import * as moment from 'moment';
import { LoadingComponent } from '../../loading/loading.component';

@Component({
  selector: 'app-earning',
  templateUrl: './earning.component.html',
  styleUrls: [ '../../../assets/css/common.css']
})
export class EarningComponent implements OnInit {

  events: any = new Object()
  error_message: any;
  loading: any;
  transaction_list: any = [];
  servicefee  = '0.00';


  constructor(public dataService: DataService, private router: Router, zone: NgZone, public snackBar: MatSnackBar, public dialog: MatDialog, private element: ElementRef) { }

  ngOnInit() {
    if(!this.dataService.checkIfLogged()){
      this.router.navigate(['/']);
    }
    this.events = this.dataService.getLocalStorageData("event")

    this.getearnings()

  }


  getearnings() {

    let login_response = this.dataService.getLocalStorageData("login_response");
    let event = this.dataService.getLocalStorageData("event");

    this.loading = this.dialog.open(LoadingComponent, { disableClose: true });

    this.dataService.getData("eventtransactions?userId=" + login_response.hostId + "&event_id=" + event.id+"").subscribe((results: any) => {

      console.log(results)

      if (results.errorcode == 0) {
        this.servicefee = parseFloat(results.servicefee).toFixed(2)
        this.transaction_list = results.response.result

        console.log(this.transaction_list)
        this.loading.close();
      }
      else {
        this.showError(results.status, false);
        this.loading.close();
      }
    }, err => {
      console.log(err);
      this.showError("Unable to connect with server, please try again.", false);
      this.loading.close();
    });
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  ConvertToInt(val1:any,val2:any) {
    let totalVal= parseFloat(val1)+parseFloat(val2)
    return totalVal.toFixed(2);
  }
}
