import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';

import { DataService } from "./data.service";

@Injectable({
	providedIn: 'root'
})
// export class TokenInterceptorService implements HttpInterceptor {

//   constructor() { }



// }

export class TokenInterceptorService implements HttpInterceptor {

	constructor(public dataService: DataService) { }

	intercept(req, next) {
		let jwtToken = ""
		let isGuest = false;
		let dataService = this.dataService.getLocalStorageData("login_response");
		if (dataService) {
			let dataSer = JSON.parse(dataService)
			if (dataSer.isGuest == true) {
				isGuest = true;
			}
			else {
				jwtToken = dataSer.token;
				isGuest = false;

			}
		}

		//normal api need only request


		if (req.url.includes("https://api.apsession.com/v8") || req.url.includes("https://stagingapi.apsession.com") && isGuest == false) {
			let tokenizedReq = req.clone({
				setHeaders: {
					Authorization: jwtToken,
				}
			})
			return next.handle(tokenizedReq)
		}

		return next.handle(req)


	}
}
