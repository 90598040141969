import { Component, OnInit, NgZone, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';
import { MouseEvent } from '@agm/core';
import * as moment from 'moment';
import { LoadingComponent } from '../../loading/loading.component';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: [ '../../../assets/css/common.css']
})
export class SaleComponent implements OnInit {

  events: any = new Object();
  error_message: any;
  loading: any;
  ticketDetailsList: any = new Object();
  iosCount = 0;
  androidCount = 0;
  webCount = 0;
  totalTicketQtycount:number = 0;


  constructor(public dataService: DataService, private router: Router, zone: NgZone, public snackBar: MatSnackBar, public dialog: MatDialog, private element: ElementRef) { }
  ngOnInit() {
    if(!this.dataService.checkIfLogged()){
      this.router.navigate(['/']);
    }
    this.events = this.dataService.getLocalStorageData("event");

    this.getticketorders()
  }


  getticketorders() {

    let login_response = this.dataService.getLocalStorageData("login_response");
    console.log(event);
    this.loading = this.dialog.open(LoadingComponent, { disableClose: true });

    this.dataService.getData("ticketorders?userId=" + login_response.hostId + "&event_id=" + this.events.id + "").subscribe((results: any) => {

      if (results.errorcode == 0) {
        if(results.response.result.length > 0){
          this.ticketDetailsList = results.response.result[0]
          let downloaded_sourceList = results.response.downloaded_source;
          let totalpurcahsed_tickets = results.response.totalpurcahsed_tickets;
          let totalTicketQty=0;
          
          if (this.ticketDetailsList.generalQty){
            totalTicketQty += parseInt(this.ticketDetailsList.generalQty);
          }

          if (this.ticketDetailsList.vipQty) {
            totalTicketQty += parseInt(this.ticketDetailsList.vipQty);
          }

          if (this.ticketDetailsList.custom1Qty) {
            totalTicketQty += parseInt(this.ticketDetailsList.custom1Qty);
          }

          if (this.ticketDetailsList.custom2Qty) {
            totalTicketQty += parseInt(this.ticketDetailsList.custom2Qty);
          }

          if (this.ticketDetailsList.custom3Qty) {
            totalTicketQty += parseInt(this.ticketDetailsList.custom3Qty);
          }

          this.totalTicketQtycount = this.getcalValue(totalpurcahsed_tickets, totalTicketQty);

          if (downloaded_sourceList.length>0){
            for (let downloadsource of downloaded_sourceList) {
              if (downloadsource.purchase_source=="Android"){
                this.androidCount = this.getcalValue(downloadsource.totalpurcahsed, totalpurcahsed_tickets);
                console.log(this.androidCount,"Android");

              } else if (downloadsource.purchase_source == "iOS") {
                this.iosCount = this.getcalValue(downloadsource.totalpurcahsed, totalpurcahsed_tickets);
                console.log(this.iosCount,"ios");

              } else if (downloadsource.purchase_source == "online") {
                this.webCount = this.getcalValue(downloadsource.totalpurcahsed, totalpurcahsed_tickets);
                console.log(this.webCount,"online");
              }
            }
          }
        }
      } else {
        this.showError(results.status, false);
      }
      this.loading.close();

    }, err => {
      console.log(err);
      this.showError('Unable to connect with server, please try again.', false);
      this.loading.close();
    });
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }



  getcalValue(val1: any, val2: any) {

    if (!val1 || parseInt(val1) == 0) {
      return 0;
    } else if (val1 && parseInt(val1) == val2) {
      return 100;
    } else {
      return (parseInt(val1) / parseInt(val2) *100)
    }
  }


}
