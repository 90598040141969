import { Component, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog, MatBottomSheet, MatBottomSheetRef } from '@angular/material';
import * as moment from 'moment';

import { LoadingComponent } from '../loading/loading.component';
import { LoginComponent } from '../login/login.component';
import { MainService } from '../main.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../../assets/css/common.css']
})
export class ResetPasswordComponent implements OnInit {

  loading: any;
  token: any;
  isValid: boolean = false;
  tokenInfo: any
  currentUser: any;
  isLoggedIn: any = false;


  constructor(public route: ActivatedRoute, private _MainService: MainService, public dataService: DataService, private router: Router, zone: NgZone, public snackBar: MatSnackBar, public dialog: MatDialog, private element: ElementRef, private bottomSheet: MatBottomSheet) {
    this.isLoggedIn = this.dataService.checkIfLogged();

    if (this.route.snapshot.paramMap.get('token')) {
      this.token = this.route.snapshot.paramMap.get('token')
    }

  }

  ngOnInit() {
    this.currentUser = localStorage.getItem('login_response') ? JSON.parse(localStorage.getItem('apsession-user')) : null;

    if (this.token) {
      this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
      let request_data = new FormData();
      request_data.append('token', this.token);

      this.dataService.postData('reset', request_data, {}).then((results: any) => {
        if (results.errorcode == 0) {
          this.loading.close();
          this.isValid = true;
          this.tokenInfo = results.result
        }
        else {
          this.loading.close();
        }
      }, err => {
        this.loading.close();
      });
    }
  }
  home() {
    this.router.navigate(['/']);
  }

  signin() {
    let dialogRef = this.dialog.open(LoginComponent, {
      width: '600px',
      panelClass: 'login-container'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.isLoggedIn = this.dataService.checkIfLogged();

      if (this.isLoggedIn) {
        this.router.navigate(['/newevent']);
      }
    });
  }

  createvent() {
    if (this.isLoggedIn) {
      this.router.navigate(['/newevent']);
    } else {

      let dialogRef = this.dialog.open(LoginComponent, {
        width: '600px',
        panelClass: 'login-container'
      });
      dialogRef.afterClosed().subscribe(result => {
        this.isLoggedIn = this.dataService.checkIfLogged();

        if (this.isLoggedIn) {
          this.router.navigate(['/newevent']);
        }
      });
    }

  }

  eventinfo(eventinfo) {
    console.log(eventinfo)

    if (typeof eventinfo.password == 'undefined' || eventinfo.password.trim() == '' || eventinfo.password == null || !eventinfo.password.match("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})")) {
      this.showError('Password should contains 1 digit and one special character!', false);
      return false;
    }
    else if (typeof eventinfo.confirmpassword == "undefined" || eventinfo.confirmpassword == '' || eventinfo.confirmpassword == null) {
      this.showError('Enter confirm password!', false);
      return false;
    } else if (eventinfo.confirmpassword != eventinfo.password) {
      this.showError('Enter password and confirm password do not match!', false);
      return false;
    } else {
      this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
      // let request_data = new FormData();
      // request_data.append('new_password', eventinfo.password);
      // request_data.append('token', this.token);
      // request_data.append('email_id', this.tokenInfo.email_id);


      let body = { "request_type": { "request_name": "change_password" }, "requestinfo": { "id": this.tokenInfo.id, "newpassword": eventinfo.password } }

      this._MainService.resetPassword(body).subscribe((results: any) => {
        if (results.errorcode == 0) {
          this.loading.close();
          this.showSuccess('Password changed successfully!', false);
          this.router.navigate(['/']);
        }
        else {
          this.showError(results.status, false);
          this.loading.close();
        }
      }, err => {
        console.log(err);
        this.showError('Unable to connect with server, please try again.', false);
        this.loading.close();
      });
    }
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }


  showSuccess(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-success'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

}
