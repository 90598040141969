import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FacebookLoginProvider, GoogleLoginProvider, LinkedInLoginProvider, AuthService, SocialUser } from "angularx-social-login";
import { DataService } from '../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog, MatDialogRef } from '@angular/material';
import { LoadingComponent } from '../loading/loading.component';
import { GetEmailComponent } from '../get-email/get-email.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { from } from 'rxjs';
import * as moment from 'moment';
import { MainService } from '../main.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import { ReCaptcha2Component } from 'ngx-captcha';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('focusOn') focusOn: any;
  user_detail: any = {};
  register_master: any = {};
  loading: any;
  isLoginShown: any = true;
  birth_years: any = ["Select Birth Year"];
  loginForm: FormGroup;
  registerForm: FormGroup;
  isSubmitted: boolean = false;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  @ViewChild('langInput') langInput: ElementRef;
  protected aFormGroup: FormGroup;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  private isMobileView = false;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';

  constructor(public dataService: DataService,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LoginComponent>,
    private _FormBuilder: FormBuilder,
    private _MainService: MainService,
  ) {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.isMobileView = true;
      console.log("detected mobile ============================")
     }

    this.loginForm = this._FormBuilder.group({
      email: [null, [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: [null, [Validators.required]]
    });

    this.registerForm = this._FormBuilder.group({
      userName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: [null, [Validators.required]],
      recaptcha: ['', this.isMobileView ? "" : [Validators.required]]
    })
    var year = moment().year();
    for (let i = year; i >= year - 100; i--) {
      this.birth_years.push(i);
    }
    this.register_master.birthyear = this.birth_years[0];

  }

  ngOnInit() {
    this.isLoginShown = true;
    this.loginForm.controls.email.markAsTouched()

  }

  toggleForms() {
    this.isLoginShown = !this.isLoginShown;
  }

  do_signup() {
    if (this.registerForm.valid) {
      this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
      /* "Birth Birth Year" */
      const body = {
        request_type: {
          request_name: "signup"
        },
        requestinfo: {
          username: this.registerForm.controls['userName'].value,
          password: this.registerForm.controls['password'].value,
          email: this.registerForm.controls['email'].value,
          device_id: "324324234234234234",
          device_type: "W",
          device_token: "432423423423423423"
        }
      }
      this._MainService.userPostRequest(body).subscribe((results: any) => {

        console.log(results);
        if (results.errorcode == 0) {
          this.loading.close();
          this.dialogRef.close(results);
          this.showSuccess("Registeration success", false);
        }
        else {
          this.showError(results.status, false);
          this.loading.close();
          this.showError("Registeration failed", false);
        }
      }, err => {
        console.log(err);
        this.showError("Unable to connect with server, please try again.", false);
        this.loading.close();
      });
    }
  }

  doLoginWithFB(): void {
    from(this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)).subscribe((response) => {
      if (!response.email || response.email.trim() == "") {
        let dialogRef = this.dialog.open(GetEmailComponent, {
          width: '600px',
          panelClass: 'phonenumber-container'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.email) {
            response.email = result.email;
            this.signUpForSocialUser(response);
          }
        });
      }
      else {
        this.signUpForSocialUser(response);
      }
    }, err => {
      console.log("Error", err);
    });
  }

  get accessForm() {
    return this.loginForm.controls;
  }
  get accessRegisterForm() {
    return this.registerForm.controls;
  }

  guestLogin() {
    let res = {}
    this.dialogRef.close(res);
    this.showSuccess("Logged in as a Guest", false);
    this.dataService.setLocalStorageData("login_response", JSON.stringify({ ...res, isGuest: true }));
    this.dataService.setUser({ ...res, isGuest: true });
  }

  handleSuccess(event) {
    console.log(event);
  };

  do_login() {

    this.isSubmitted = true;
    if (this.loginForm.valid) {
      const body = {
        request_type: {
          request_name: "login"
        },
        requestinfo: {
          username: this.loginForm.controls['email'].value,
          password: this.loginForm.controls['password'].value,
          device_type: "W",
          device_token: "asdfhakjshdfasjkdfkasdfhkjh"
        }
      }

      if (this.loginForm.valid) {
        this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
        // return
        this._MainService.userPostRequest(body).pipe().subscribe((res: any) => {
          this.loading.close();
          if (res.errorcode == '0') {

            this.isSubmitted = false;
            this.showSuccess("Login success", false);

            this.dataService.setLocalStorageData("login_response", JSON.stringify({ ...res.result, isGuest: false }));
            this.dataService.setUser({ ...res.result, isGuest: false });
            this.dialogRef.close(res);
          }
          else {
            this.isSubmitted = false;
            this.showError(res.message, false);
          }
        }, err => {
          this.loading.close();
          console.log(err);
          this.showError(err.message, false);
        })
      }


      // this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
      // this.dataService.postData("doLogin", request_data, {}).then((results: any) => {
      //   if (results.errorcode == 0) {
      //     this.dataService.setLocalStorageData("login_response", results);
      //     this.loading.close();
      //     this.dialogRef.close(results);
      //   }
      //   else {
      //     this.showError(results.status, false);
      //     this.loading.close();
      //   }
      // }, err => {
      //   console.log(err);
      //   this.showError("Unable to connect with server, please try again.", false);
      //   this.loading.close();
      // });
    }

    else {

    }
  }


  signUpForSocialUser(user_detail) {
    this.loading = this.dialog.open(LoadingComponent, { disableClose: true });

    let request_data = new FormData();
    request_data.append("social_uid", user_detail.id);
    request_data.append("username", user_detail.name);
    request_data.append("email", user_detail.email);
    request_data.append("deviceid", "");
    request_data.append("parish", "");
    if (user_detail.birthday) {
      request_data.append("birthyear", user_detail.birthday);
    }
    const body = { "requestinfo": { "socialid": user_detail.id, "signup_type": 3, "email": user_detail.email, "device_id": "845B0898-DF90-43BB-9AF2-3CD9C7C2D87B", "birthyear": user_detail.birthday, "device_type": "I", "password": "", "username": user_detail.name, "device_token": "d8tX6m_ZUUsUhjZq0NCB0r:APA91bEuEI-uobu7JCkhbVPQsZ2uQnKJmkVCsxUjzD-34t0uOstIZY02fyrcGBJq_KB6t8YLL6YnbGVKqWYMTbEPQT8u_7aVdos7nKw_yjwBXiVw-kiRVFmH1GSn9431DX_xrUD3CRV7" }, "request_type": { "request_name": "social_login" } }
    this._MainService.socialLogin(body).subscribe((results: any) => {
      if (results.errorcode == 0) {
        this.dataService.setLocalStorageData("login_response", JSON.stringify({ ...results.result, isGuest: false }));
        this.loading.close();
        this.dialogRef.close(results.result);
        this.dataService.setUser({ ...results.result, isGuest: false });
        // location.reload()
      }
      else {
        this.showError(results.status, false);
        this.loading.close();
      }
    }, err => {
      console.log(err);
      this.showError("Unable to connect with server, please try again.", false);
      this.loading.close();
    });
  }

  loginWithGoogle() {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
      console.log('google user', x)
      this.signUpForSocialUser(x);

    }).catch(err => {
      console.log(err)
    });
  }

  openForgotPassword() {
    let dialogRef = this.dialog.open(ForgotPasswordComponent, {
      width: '500px',
      panelClass: 'phonenumber-container'
    });
    dialogRef.afterClosed().subscribe(result => {
    });


    this.dialogRef.close()
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  showSuccess(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-success'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

}
