/* ******************************************************************************
OptimumBrew Technology Pvt. Ltd.

Title:            Apsession v2
File:             View Image Dialog TS
Since:            21 Sept, 2018
Author:           Umesh Patadiya
Email:            umeshpatadiya1995@gmail.com

****************************************************************************** */
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.css']
})
export class ViewImageComponent implements OnInit {

  imageSRC: any = "";

  constructor(public dialogRef: MatDialogRef<ViewImageComponent>) { }

  ngOnInit() {
  }

}
