/* ******************************************************************************
OptimumBrew Technology Pvt. Ltd.

Title:            Get Directions TS
Since:            21 Sept, 2018
Author:           Umesh Patadiya
Email:            umeshpatadiya1995@gmail.com

****************************************************************************** */

import { Component, OnInit, NgZone, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog, MatBottomSheet, MatBottomSheetRef } from '@angular/material';
import { MouseEvent } from '@agm/core';
import * as moment from 'moment';

@Component({
  selector: 'app-get-directions',
  templateUrl: './get-directions.component.html',
  styleUrls: ['./get-directions.component.css']
})
export class GetDirectionsComponent implements OnInit {

  /* destination: any = {};
  origin: any = {}; */
  origin: any = { lat: 24.799448, lng: 120.979021 }
  destination: any = { lat: 24.799524, lng: 120.975017 }

  constructor() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  ngOnInit() {
  }

}
