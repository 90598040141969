/* ******************************************************************************
OptimumBrew Technology Pvt. Ltd.

Title:            Delete confirmation TS
Since:            25 Sept, 2018
Author:           Umesh Patadiya
Email:            umeshpatadiya1995@gmail.com

****************************************************************************** */

import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog, MatDialogRef } from '@angular/material';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.css']
})
export class DeleteConfirmationComponent implements OnInit {

  display_title: any;
  display_msg: any;
  cancelText: any;
  okText: any;

  constructor(public dataService: DataService, public snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<DeleteConfirmationComponent>) {
  }

  ngOnInit() {
  }

  proceedToAction() {
    this.dialogRef.close({ "delete": true });
  }

}
