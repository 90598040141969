import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatDialog, MatDialogRef } from '@angular/material';
import { LoadingComponent } from '../loading/loading.component';
import { LoginComponent } from '../login/login.component';
import { NgForm } from '@angular/forms';
import { MainService } from '../main.service';


declare var Stripe: any;

@Component({
  selector: 'app-payment-screen',
  templateUrl: './payment-screen.component.html',
  styleUrls: ['./payment-screen.component.css']
})
export class PaymentScreenComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('cardInfo') cardInfo: ElementRef;
  isCollapsed: boolean = true;
  card: any;
  stripe: any;
  selected_tickets: any = [];
  login_response: any = {};
  event_details: any = {};
  unique_tickets: any = [];
  grand_total: any = 0;
  cardHandler = this.onChange.bind(this);
  windowWidth: any = window.innerWidth;
  error: string;
  loading: any;
  description: any = "";
  btnDisable = false
  newUniqueTickets: any;
  constructor(public dataService: DataService, public snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<PaymentScreenComponent>, private cd: ChangeDetectorRef, public router: Router, private _MainService: MainService) {
    this.login_response = JSON.parse(this.dataService.getLocalStorageData("login_response"));
    this.unique_tickets = this.dataService.getLocalStorageData("unique_tickets");
    this.event_details = this.dataService.getLocalStorageData("event_details");

    if (this.unique_tickets) {

      // this.unique_tickets.forEach(element => {
      //   element.advancePrice = parseInt(element.advancePrice);
      //   this.grand_total = this.grand_total + element.total_price;
      //   element.total_price = parseFloat(element.total_price).toFixed(2);
      // });
      // this.grand_total = parseFloat(this.grand_total).toFixed(2);
    }

    if (!this.login_response) {
      this.dialog.open(LoginComponent, {
        width: '600px',
        panelClass: 'login-container'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.login_response = result;
        }
        else {
          this.dialogRef.close();
        }
      });
    }
  }

  ngOnInit() {
    this.getUniqueTickets()
  }

  ngAfterViewInit() {
    this.card = elements.create('card', {
      style:
      {
        base: {
          fontSize: "16px"
        }
      }
    });
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  getTicketDesc(data: any) {
    
    let line = '';

    const newData: any = data.filter((a, i) => data.findIndex((s) => a.quantity_name === s.quantity_name) === i)
    // let newData = this.unique_tickets.filter(z => cat.includes(z.quantity_name));
    for (const x of newData) {
      console.log(line);

      line += `${x.tickettype} ticket x ${x.quantity}, `
      console.log(line)
    }



    return line;
  }

  getUniqueTickets() {
    this.newUniqueTickets = this.unique_tickets.filter((a, i) => this.unique_tickets.findIndex((s) => a.quantity_name === s.quantity_name) === i)

    this.newUniqueTickets.forEach(element => {
      element.advancePrice = parseInt(element.advancePrice);
      this.grand_total = this.grand_total + element.total_price;
      element.fees = parseInt(element.fees) * element.Qty;
      element.total_price = parseFloat(element.total_price).toFixed(2);
    });

    this.grand_total = parseFloat(this.grand_total).toFixed(2);


  }

  async onSubmit(form: NgForm) {
    
    this.btnDisable = true;
    this.error = null;
    const { token, error } = await stripe.createToken(this.card);

    if (error) {
      this.btnDisable = false;
      this.error = error.message;
      console.log(this.error)
    } else {
      this.btnDisable = true;
      
      // this.btnDisable=true;
      console.log(this.login_response)
      let isGuest = this.login_response.isGuest;
      if(this.event_details && this.event_details.id){

      this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
      if (isGuest) {
        if (this.unique_tickets.length > 0) {
          const ticketObject = []
          this.unique_tickets.map(ticket => {
            
            ticketObject.push({ "id": 0, "event_id": this.event_details.id, "eventname": this.event_details.name, "email_id": ticket.email, "firstname": ticket.first_name, "lastname": ticket.last_name, "phone": ticket.phone, "tickettype": ticket.ticket_label, "pricepaid": ticket.advancePrice, "quantity": ticket.Qty, "quantity_name": ticket.quantity_name, "paymentstatus": "Paid", "purchase_source": "online" })
          })
          // 
          let descText = this.getTicketDesc(ticketObject);
          const body = {
            request_type: {
              request_name: "do_guestcheckoutstripepayment"
            },
            requestinfo: {
              "tickets": ticketObject,
              "token": token.id,
              "amount": this.grand_total,
              "currency": this.event_details.currency,
              "description": '#' + this.event_details.id + " " + this.event_details.name + ' payment from website By ' + this.unique_tickets[0].first_name + " " + this.unique_tickets[0].last_name + ' for ' + descText + 'Delivered to ' + this.unique_tickets[0].email
            }
          }

          this._MainService.postRequest(body).subscribe((results: any) => {

            console.log(results);
            if (results.errorcode == 0) {
              
              this.loading.close();
              localStorage.setItem("parent_id", results.parent_id);
              this.showSuccess(results.status, false);
              this.dialogRef.close();
              this.router.navigate(['/home', { state: "mytickets" }]);
            }
            else {
              this.loading.close();
              this.showError(results.status, false);
              this.dialogRef.close();
            }
          }, err => {
            console.log(err);
            this.showError(err.error.text, false);
            this.loading.close();
            this.dialogRef.close();
          });

        }
      }
      else {
        if (this.unique_tickets.length > 0) {
          const ticketObject = []
          this.unique_tickets.map(ticket => {
            ticketObject.push({ "id": this.login_response.hostId, "event_id": this.event_details.id, "eventname": this.event_details.name, "email_id": ticket.email, "firstname": ticket.first_name, "lastname": ticket.last_name, "phone": "", "tickettype": ticket.ticket_label, "pricepaid": ticket.advancePrice, "quantity": ticket.Qty, "quantity_name": ticket.quantity_name, "paymentstatus": "Paid", "purchase_source": "online" })
          })
          // 
          let descText = this.getTicketDesc(ticketObject);

          const body = {
            request_type: {
              request_name: "do_livestripepayment"
            },
            requestinfo: {
              "tickets": ticketObject,
              "token": token.id,
              "amount": this.grand_total,
              "currency": this.event_details.currency,
              "id": this.login_response.hostId,
              "description": '#' + this.event_details.id + " " + this.event_details.name + ' payment from website By ' + this.login_response.username + ' for ' + descText + 'Delivered to ' + this.login_response.email,
            }
          }

          this._MainService.postRequest(body).subscribe((results: any) => {

            console.log(results);
            if (results.errorcode == 0) {
              
              this.loading.close();
              localStorage.setItem("parent_id", results.parent_id);
              this.showSuccess(results.status, false);
              this.dialogRef.close();
              this.router.navigate(['/mytickets', { state: "mytickets" }]);

            }
            else {
              this.loading.close();
              this.showError(results.status, false);
              this.dialogRef.close();
            }
          }, err => {
            console.log(err);
            this.showError(err.error.text, false);
            this.loading.close();
            this.dialogRef.close();
          });

        }
      }
    }

    else{
      this.btnDisable=false;
      this.showError("Event not found","")
      
    }

    }
  }



  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  showSuccess(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-success'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }


  switchSlide(){
    this.isCollapsed = !this.isCollapsed
    if(!this.isCollapsed){
      document.getElementById("footer").scrollIntoView({behavior:"smooth"})
    }
  }

}
