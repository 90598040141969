import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { MainService } from "../main.service";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { LoadingComponent } from "../loading/loading.component";
import { MatDialog } from "@angular/material";
import { BsModalRef, BsModalService } from "ngx-bootstrap";


@Component({
    selector: 'app-ticket-download',
    templateUrl: './ticket-downloader.component.html',
    styleUrls: ['./ticket-downloader.component.css', '../../assets/css/common.css']
})


export class TicketDownloader implements OnInit {
    currentYear = new Date().getFullYear()
    tickets_list: any = []
    loading: any;
    modalRef: BsModalRef;
    parentId: any = 0;
    constructor(public dataService: DataService, private _MainService: MainService, public dialog: MatDialog, public _BsModalService: BsModalService, public _BsModalRef: BsModalRef) {
        // this.dd=this._ElementRef.nativeElement.innerHTML;
        // console.log("eleref=",dd)
    }
    ngOnInit(): void {
        this.parentId = localStorage.getItem("parent_id");
        this.getallmytickets()
        console.log("hello this compnent");
    }

    callByRef() {
        console.log('Hi here is call by reference')
    }

    getallmytickets() {
        let login_response = this.dataService.getLocalStorageData("login_response");
        const body = {
            request_type: {
                request_name: "getTicketById"
            },
            requestinfo: { "parent_id": this.parentId }
        }

        this.loading = this.dialog.open(LoadingComponent, { disableClose: true });

        this._MainService.postRequest(body).subscribe((res: any) => {
            this.loading.close()
            console.log(res);
            if (res.errorcode == 0) {
                this.tickets_list = res.response.result;
                this.tickets_list.forEach(x => {
                    let img = x.barcode.replace("https://s3.amazonaws.com/apsession/", "https://apsession.s3.amazonaws.com/")
                    this.convertImg(img).then(y => {
                        x.barcode = y
                    });
                })
            }
            else {
                this.tickets_list = [];

            }
        }, err => {
            console.log(err);
        });
    }

    closeModal() {
        this._BsModalService.hide(2)
        // this._BsModalRef.hide()
    }
    downloadTickets() {
        let pdf;
        this.loading = this.dialog.open(LoadingComponent, { disableClose: true });

        html2canvas(document.getElementById('printformClass1')).then((canvas) => {
            pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
            var imgData = canvas.toDataURL('image/jpeg', 1.0);
            pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);

            for (let index = 1; index < this.tickets_list.length; index++) {
                const element = this.tickets_list[index];
                html2canvas(document.getElementById(`printformClass${index + 1}`)).then((canvas) => {
                    var imgData = canvas.toDataURL('image/jpeg', 1.0);
                    pdf.addPage('image/jpeg', 'p');
                    pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);
                })
            }

        });

        setTimeout(() => {
            this.loading.close();
            pdf.save(`ticket(s).pdf`);
            this._BsModalService.hide(2)

        }, 10000);
    }


    convertImg(url) {
        return this.dataService.getBase64ImageFromUrl(url).then(x => {
            console.log(x)
            return x;

        });
    }
}