
import { Component, OnInit, NgZone, ElementRef } from '@angular/core';
import { DataService } from '../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog, MatDialogRef } from '@angular/material';
import { LoadingComponent } from '../loading/loading.component';
import { LoginComponent } from '../login/login.component';
import { PaymentScreenComponent } from '../payment-screen/payment-screen.component';
import { GetPhoneNoComponent } from '../get-phone-no/get-phone-no.component';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';

@Component({
  selector: 'app-register-tickets',
  templateUrl: './register-tickets.component.html',
  styleUrls: ['./register-tickets.component.css']
})
export class RegisterTicketsComponent implements OnInit {

  event_details: any = {};
  checkout_val: any = 0;
  ticket_count: any = 0;
  login_response: any = {};
  facebook_response: any = {};
  selected_tickets: any = [];
  active_ticket: any = {};
  claimAll: any = false;
  currIdx = 0;
  ticket_details: any;
  isGuest: boolean = false;
  showErr: boolean = false;
  constructor(public dataService: DataService, public snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<RegisterTicketsComponent>) {
  }

  ngOnInit() {
    
    this.ticket_details = this.dialogRef.componentInstance.event_details;
    this.login_response = JSON.parse(localStorage.getItem('login_response'));
    if (JSON.parse(this.login_response).isGuest == true) {
      this.isGuest = true;
    } else {
      this.isGuest = false;

    }
    this.selected_tickets = this.dataService.getLocalStorageData("selected_tickets");
    if (this.selected_tickets) {
      this.selected_tickets[this.currIdx].activeClass = "active-tickt";
      this.active_ticket = this.selected_tickets[this.currIdx];
    }



    if (this.login_response) {
      // this.selected_tickets[this.currIdx].email = this.login_response.email;
      this.activateTicket(this.selected_tickets[0], 0)
    }
    else {
      let dialogRef = this.dialog.open(LoginComponent, {
        width: '600px',
        panelClass: 'login-container'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.login_response = result;
          // this.selected_tickets[this.currIdx].email = this.login_response.email;
        }
        else {
          this.dialogRef.close();
        }
      });
    }



  }

  indexTracker(index: number, value: any) {
    return index;
  }
  activateTicket(ticket_details, i?) {
    this.currIdx = i;
    this.active_ticket = ticket_details;

    for (let j = 0; j < this.selected_tickets.length; j++) {
      if (this.isGuest == false) {
        this.active_ticket.email = JSON.parse(this.login_response).email;
        this.selected_tickets[j].email = JSON.parse(this.login_response).email;
      }
      if (i == j) {
        this.selected_tickets[j].activeClass = "active-tickt";
      }
      else {
        this.selected_tickets[j].activeClass = "";
      }
    }
  }
  activateTicket2(ticket_details, i?) {
    this.currIdx = i;
    this.active_ticket = ticket_details;

    for (let j = 0; j < this.selected_tickets.length; j++) {
      // if (this.isGuest == false) {
      //   this.active_ticket.email = JSON.parse(this.login_response).email;
      // }
      if (i == j) {
        this.selected_tickets[j].activeClass = "active-tickt";
      }
      else {
        this.selected_tickets[j].activeClass = "";
      }
    }
  }

  checkAllTickets(claimAll: any) {
    console.log(claimAll);
    if (claimAll == true || claimAll == 1) {
      if (typeof this.selected_tickets[0].first_name == "undefined" || this.selected_tickets[0].first_name.trim() == "" || this.selected_tickets[0].first_name == null) {
        this.showError("Please fill all the fields", false);
        this.claimAll = !this.claimAll;
        this.selected_tickets[0].is_completed = false;
        return false;
      }
      else if (typeof this.selected_tickets[0].last_name == "undefined" || this.selected_tickets[0].last_name.trim() == "" || this.selected_tickets[0].last_name == null) {
        this.showError("Please fill all the fields", false);
        this.claimAll = !this.claimAll;
        this.selected_tickets[0].is_completed = false;
        return false;
      }
      else if (typeof this.selected_tickets[0].email == "undefined" || this.selected_tickets[0].email.trim() == "" || this.selected_tickets[0].email == null) {
        this.showError("Please fill all the fields", false);
        this.claimAll = !this.claimAll;
        this.selected_tickets[0].is_completed = false;
        return false;
      }
      else if ((typeof this.selected_tickets[0].confirm_email == "undefined" || this.selected_tickets[0].confirm_email.trim() == "" || this.selected_tickets[0].confirm_email == null) && this.isGuest == true) {
        this.showError("Please fill all the fields", false);
        this.claimAll = !this.claimAll;
        this.selected_tickets[0].is_completed = false;
        return false;
      }
      else if ((typeof this.selected_tickets[0].phone == "undefined" || this.selected_tickets[0].phone.trim() == "" || this.selected_tickets[0].phone == null) && this.isGuest == true) {
        this.showError("Please fill all the fields", false);
        this.claimAll = !this.claimAll;
        this.selected_tickets[0].is_completed = false;
        return false;
      }
      else {

        this.selected_tickets[0].is_completed = true;
        for (let i = 1; i < this.selected_tickets.length; i++) {
          this.selected_tickets[i].first_name = this.selected_tickets[0].first_name;
          this.selected_tickets[i].last_name = this.selected_tickets[0].last_name;
          this.selected_tickets[i].email = this.selected_tickets[0].email;
          this.selected_tickets[i].is_completed = true;
          if (this.isGuest == true) {
            this.selected_tickets[i].confirm_email = this.selected_tickets[0].confirm_email;
            this.selected_tickets[i].phone = this.selected_tickets[0].phone;
          }
        }
      }
    }
    else {
      for (let i = 1; i < this.selected_tickets.length; i++) {
        this.selected_tickets[i].first_name = "";
        this.selected_tickets[i].last_name = "";
        this.selected_tickets[i].email = "";
        this.selected_tickets[i].is_completed = false;
      }
    }
  }

  deleteTicket(i, ticket_details) {
    let dialogRef = this.dialog.open(DeleteConfirmationComponent);
    dialogRef.componentInstance.display_title = "Cancel Ticket";
    dialogRef.componentInstance.display_msg = "Are you sure you want to cancel Ticket " + (i + 1) + "?";
    dialogRef.componentInstance.cancelText = "No";
    dialogRef.componentInstance.okText = "Yes";
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.delete && result.delete == true) {
        this.selected_tickets.splice(i, 1);
        this.dataService.setLocalStorageData("selected_tickets", this.selected_tickets);
        let unique_tickets: any = [];
        this.event_details.tickets.forEach(element => {
          if (element.Qty && element.Qty > 0) {
            unique_tickets.push(element);
          }
        });
        var flags = [], output = [], l = this.selected_tickets.length, k;
        for (k = 0; k < l; k++) {
          if (flags[this.selected_tickets[k].ticket_label]) continue;
          flags[this.selected_tickets[k].ticket_label] = true;
          output.push(this.selected_tickets[k]);
        }
        this.dataService.setLocalStorageData("unique_tickets", unique_tickets);
      }
      else {
      }
    });
  }

  goToNextTicket() {
    
    this.login_response = this.dataService.getLocalStorageData("login_response");
    if (this.currIdx < this.selected_tickets.length - 1) {
      if (typeof this.selected_tickets[this.currIdx].first_name == "undefined" || this.selected_tickets[this.currIdx].first_name.trim() == "" || this.selected_tickets[this.currIdx].first_name == null) {
        this.showError("Please fill all the fields", false);
        this.selected_tickets[this.currIdx].is_completed = false;
        return false;
      }
      else if (typeof this.selected_tickets[this.currIdx].last_name == "undefined" || this.selected_tickets[this.currIdx].last_name.trim() == "" || this.selected_tickets[this.currIdx].last_name == null) {
        this.showError("Please fill all the fields", false);
        this.selected_tickets[this.currIdx].is_completed = false;
        return false;
      }
      else if (typeof this.selected_tickets[this.currIdx].email == "undefined" || this.selected_tickets[this.currIdx].email.trim() == "" || this.selected_tickets[this.currIdx].email == null) {
        this.showError("Please fill all the fields", false);
        this.selected_tickets[this.currIdx].is_completed = false;
        return false;
      }
      else if ((typeof this.selected_tickets[this.currIdx].confirm_email == "undefined" || this.selected_tickets[this.currIdx].confirm_email.trim() == "" || this.selected_tickets[this.currIdx].confirm_email == null) && this.isGuest == true) {
        this.showError("Please fill all the fields", false);
        this.selected_tickets[this.currIdx].is_completed = false;
        return false;
      }
      else if ((typeof this.selected_tickets[this.currIdx].phone == "undefined" || this.selected_tickets[this.currIdx].phone.trim() == "" || this.selected_tickets[this.currIdx].phone == null) && this.isGuest == true) {
        this.showError("Please fill all the fields", false);
        this.selected_tickets[this.currIdx].is_completed = false;
        return false;
      }
      else if (!this.dataService.validateEmail(this.selected_tickets[this.currIdx].confirm_email) && this.isGuest == true) {
        this.showError("Please enter valid email address", false);
        this.selected_tickets[this.currIdx].is_completed = false;
        return false;
      }
      else if (!this.dataService.validateEmail(this.selected_tickets  [this.currIdx].email)) {
        this.showError("Please enter valid email address", false);
        this.selected_tickets[this.currIdx].is_completed = false;
        return false;
      }
      else if (this.selected_tickets[this.currIdx].email != this.selected_tickets[this.currIdx].confirm_email && this.isGuest == true) {
        this.showError("email and confirm email should we same", false);
        this.selected_tickets[this.currIdx].is_completed = false;
        return false;
      }
      else {
        this.currIdx++;
        this.active_ticket = this.selected_tickets[this.currIdx];
        for (let j = 0; j < this.selected_tickets.length; j++) {
          if (this.currIdx == j) {
            this.selected_tickets[j].activeClass = "active-tickt";
          }
          else {
            this.selected_tickets[j].activeClass = "";
          }
          if (j < this.currIdx) {
            this.selected_tickets[j].is_completed = true;
          }
          else {
            this.selected_tickets[j].is_completed = false;
          }
        }
      }
    }
    else {
    }
  }

  checkVal(){
    if(this.selected_tickets[this.currIdx].email && this.selected_tickets[this.currIdx].confirm_email){
      if(this.selected_tickets[this.currIdx].email != this.selected_tickets[this.currIdx].confirm_email){
        this.showErr=true;
      }else{
        this.showErr=false;

      }
    }
    else{
      this.showErr=false;

    }
  }
  goToPayment() {
    let is_all_filled: any = true;
    for (let i = 0; i < this.selected_tickets.length; i++) {
      
      if (typeof this.selected_tickets[i].first_name == "undefined" || this.selected_tickets[i].first_name.trim() == "" || this.selected_tickets[i].first_name == null) {
        this.showError("Please fill all the fields", false);
        i = this.selected_tickets.length;
        is_all_filled = false;
        return false;
      }
      else if (typeof this.selected_tickets[i].last_name == "undefined" || this.selected_tickets[i].last_name.trim() == "" || this.selected_tickets[i].last_name == null) {
        this.showError("Please fill all the fields", false);
        i = this.selected_tickets.length;
        is_all_filled = false;
        return false;
      }
      else if (typeof this.selected_tickets[i].email == "undefined" || this.selected_tickets[i].email.trim() == "" || this.selected_tickets[i].email == null) {
        this.showError("Please fill all the fields", false);
        i = this.selected_tickets.length;
        is_all_filled = false;
        return false;
      }
      else if ((typeof this.selected_tickets[i].confirm_email == "undefined" || this.selected_tickets[i].confirm_email.trim() == "" || this.selected_tickets[i].confirm_email == null) && this.isGuest == true) {
        this.showError("Please fill all the fields", false);
        i = this.selected_tickets.length;
        is_all_filled = false;
        return false;
      }
      else if (!this.dataService.validateEmail(this.selected_tickets[i].email)) {
        this.showError("Please enter valid email address", false);
        i = this.selected_tickets.length;
        is_all_filled = false;
        return false;
      }
      else if ((typeof this.selected_tickets[i].phone == "undefined" || this.selected_tickets[i].phone.trim() == "" || this.selected_tickets[i].phone == null)   && this.isGuest == true) {
        this.showError("Please fill all the fields", false);
        i = this.selected_tickets.length;
        is_all_filled = false;
        return false;
      }
      else if (!this.dataService.validateEmail(this.selected_tickets[i].confirm_email) && this.isGuest == true) {
        this.showError("Please enter valid confirm email address", false);
        i = this.selected_tickets.length;
        is_all_filled = false;
        return false;
      }
      else {
      }
    }
    if (is_all_filled == true) {
      this.login_response = this.dataService.getLocalStorageData("login_response");
      this.dataService.setLocalStorageData("unique_tickets", this.selected_tickets);

      let dialogRef = this.dialog.open(PaymentScreenComponent, {
        width: '600px',
        panelClass: 'login-container'
      });

      dialogRef.componentInstance.selected_tickets = this.selected_tickets;
      this.dialogRef.close();
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
        else {
        }
      });

    }
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  showSuccess(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-success'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }


}
