import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppDashboardComponent } from './app-dashboard/app-dashboard.component';
import { SaleComponent } from './app-dashboard/sale/sale.component';
import { EarningComponent } from './app-dashboard/earning/earning.component';
import { ContactusComponent } from './app-dashboard/contactus/contactus.component';
import { CheckinComponent } from './app-dashboard/checkin/checkin.component';
import { PayoutComponent } from './app-dashboard/payout/payout.component';
import {OverviewComponent} from './app-dashboard/overview/overview.component';
import { NeweventComponent } from './app-dashboard/newevent/newevent.component';

const routes: Routes = [

   {
     path: 'appdash', component: AppDashboardComponent,
     children: [
         { path: '', component: OverviewComponent, pathMatch: 'full'},
         { path: 'overview', component: OverviewComponent },
         { path: 'appsale', component: SaleComponent },
         { path: 'earning', component: EarningComponent },
         { path: 'contact-us', component: ContactusComponent },
         { path: 'checkin', component: CheckinComponent },
         { path: 'payout', component: PayoutComponent },
     ]
 },

 {path: 'newevent', component: NeweventComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
