import { Component, OnInit, NgZone, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css', '../../assets/css/common.css']
})
export class DashboardComponent implements OnInit {

  error_message: any;
  loading: any;
  barcode_val: any;
  events_list: any = [];
  event: any = new Object();
  live :number =0; 
  past :number =0; 
  draft :number =0; 
  requestType  ="live"


  constructor(public dataService: DataService, private router: Router, zone: NgZone, public snackBar: MatSnackBar, public dialog: MatDialog, private element: ElementRef) { }

  ngOnInit() {
   

    if(!this.dataService.checkIfLogged()){
      this.router.navigate(['/']);
    }


    this.geteventslist();
  }

  loadevents(eventval){   
    this.requestType=eventval
    this.geteventslist()
  }


  geteventslist() {

    let login_response = this.dataService.getLocalStorageData("login_response");

    this.loading = this.dialog.open(LoadingComponent, { disableClose: true });

    this.dataService.getData("allevent?userId=" + login_response.hostId+"&event_type="+this.requestType).subscribe((results: any) => {

      if (results.errorcode == 0) {
        this.events_list = results.response.result

        this.draft=results.response.draft
        this.live=results.response.live
        this.past=results.response.past

        console.log(this.events_list)
        this.loading.close();
      }
      else {
        this.showError(results.status, false);
        this.loading.close();
      }
    }, err => {
      console.log(err);
      this.showError("Unable to connect with server, please try again.", false);
      this.loading.close();
    });
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  opendashboardView(event) {  
    this.dataService.setLocalStorageData("event", event);
    this.router.navigate(['/appdash/overview']);
  }

  mytickets(){
    this.router.navigate(['/mytickets']);
  }

  dashboard(){
    this.router.navigate(['/dashboard']) .then(() => {
      window.location.reload();
    });
  }

  home(){
    this.router.navigate(['/home']);
  }

  

  logout (){
    this.dataService.doLogOut();
    this.router.navigate(['/']);
  }

  createticket(){
    this.router.navigate(['/newevent']);
  }

}
