/* ******************************************************************************
OptimumBrew Technology Pvt. Ltd.

Title:            Apsession v2
File:             Sharing Dialog TS
Since:            21 Sept, 2018
Author:           Umesh Patadiya
Email:            umeshpatadiya1995@gmail.com

****************************************************************************** */
import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';

@Component({
  selector: 'app-sharing-dialog',
  templateUrl: './sharing-dialog.component.html',
  styleUrls: ['./sharing-dialog.component.css']
})
export class SharingDialogComponent implements OnInit {

  sharingURL: any;

  constructor(private bottomSheetRef: MatBottomSheetRef<SharingDialogComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    this.sharingURL = this.data.sharingURL;
  }

  ngOnInit() {
  }

  close(){
    this.bottomSheetRef.dismiss("")
  }

}
