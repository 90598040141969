import { Component, OnInit, NgZone, ElementRef, AfterViewInit, ViewChild, } from '@angular/core';
import $ from "jquery";
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';
import * as moment from 'moment';
import { LoadingComponent } from '../loading/loading.component';
import { LoginComponent } from '../login/login.component';
import * as CryptoJS from 'crypto-js';
import { MainService } from '../main.service';
import { catchError } from 'rxjs/operators';
import { GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TicketDownloader } from '../ticket-downloader/ticket-downloader.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('ticketModal') ticketModal: any;
  @ViewChild('closeBtn') closeBtn: any;
  isMobile = false;
  modalRef: BsModalRef;
  matSelectedIndex = 0;
  eventsData: any = [];
  mapCordinates: any = []
  isShrunk: boolean;
  country_list: any = [];
  city_list: any = [];
  category_list: any = [];
  categoryList: any = [];

  selected_country: any = {};
  selected_city: any = 'Select City';
  selected_category: any = 'Select Category';
  selected_Date: string = "Select Date";
  user_search: any = "";
  events_list: any = [];
  display_events_list: any = [];
  total_events: any;
  map_events: any = [];
  previous;
  error_message: any;
  loading: any;
  isLoggedIn: any = false;
  dateList: any = ["Select Date", "Today", "Yesterday"];
  // google maps zoom level
  zoom: number = 8;

  // initial center position for the map
  lat: number = 51.673858;
  lng: number = 7.815982;
  currentUser: any;
  markers: any = [];
  eventLoading = false;
  @ViewChild('AgmMap') agmMap: AgmMap;
  eventFinalIndex = 18;
  pageNumber = 1;
  appLink = "";
  constructor(public dataService: DataService,
    private router: Router,
    zone: NgZone,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private element: ElementRef,
    private _MainService: MainService,
    private _ActivatedRoute: ActivatedRoute,
    private modalService: BsModalService
  ) {
    this.isLoggedIn = this.dataService.checkIfLogged();
    // let scrollUp = this.router.events.subscribe((path) => {
    //   element.nativeElement.scrollIntoView();
    // });
    // window.onscroll = () => {
    //   zone.run(() => {
    //     if (window.pageYOffset > 150) {
    //       this.isShrunk = true;
    //     } else {
    //       this.isShrunk = false;
    //     }
    //   });
    // }

  }
  ngAfterViewInit() {
    window.onscroll = function () { scrollFunction() };
    function scrollFunction() {
      if (document.body.scrollTop > 60 || document.documentElement.scrollTop > 60) {
        document.getElementById("mobile-menu")!.style.position = "fixed";
        document.getElementById("mobile-menu")!.style.zIndex = "999";
        document.getElementById("mobile-menu")!.style.fontSize = "1rem";
        document.getElementById("mobile-menu")!.style.backgroundColor = "#ffea00";
        document.getElementById("mobile-menu")!.style.transition = "0.4s";
        document.getElementById("mobile-menu")!.style.boxShadow = "1px 1px 8px 1px rgba(102,102,102,.6)";
        // document.getElementById("mobile-menu")!.style.top = "9px"; 
        // document.getElementById("logo").style.width = "170px"; 
      } else if (document.body.scrollTop < 60 || document.documentElement.scrollTop < 60) {
        // document.getElementById("logo").style.width = "180px";
        document.getElementById("mobile-menu")!.style.position = "fixed";
        document.getElementById("mobile-menu")!.style.zIndex = "999";
        document.getElementById("mobile-menu")!.style.backgroundColor = "#ffea0000";
        document.getElementById("mobile-menu")!.style.fontSize = "1.05rem";
        document.getElementById("mobile-menu")!.style.boxShadow = "0px 0px 0px 0px rgba(0,0,0,0)";
        // document.getElementById("mobile-menu")!.style.top = "0px"; 
      }
    }

    var button = document.getElementById('slide');
    button.onclick = function () {
      console.log("click")
      var container = document.getElementById('container-scroll');
      sideScroll(container, 'right', 25, 100, 10);
    };

    var back = document.getElementById('slideBack');
    back.onclick = function () {
      var container = document.getElementById('container-scroll');
      sideScroll(container, 'left', 25, 100, 10);
    };

    function sideScroll(element, direction, speed, distance, step) {
      let scrollAmount = 0;
      var slideTimer = setInterval(function () {
        if (direction == 'left') {
          element.scrollLeft -= step;
        } else {
          element.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
      }, speed);
    }
  }

  ngOnInit() {


    var data: any = this._ActivatedRoute.snapshot.params;
    if (data.state == "mytickets") {
      this.modalRef = this.modalService.show(this.ticketModal, { animated: true, keyboard: false, backdrop: "static", class: "ticket-modal" });
    }


    this.isMobile = this.dataService.isMobileDevice()
    if (this.dataService.checkOS() == "IOS") {
      this.appLink = "https://apps.apple.com/app/id802888811";
    }
    else if (this.dataService.checkOS() == "ANDROID") {
      this.appLink = "https://play.google.com/store/apps/details?id=com.apsession.air.apsessionuser";
    } else {
      this.appLink = "https://play.google.com/store/apps/details?id=com.apsession.air.apsessionuser";
    }

    var data = this.dataService.getLocalStorageData("login_response");
    if (data) {

      this.currentUser = JSON.parse(data);
      if (this.currentUser) {
        this.isLoggedIn = true;
      }
      else {
        this.currentUser = null;
        this.isLoggedIn = false;
      }
    }
    else {
      this.currentUser = null;
      this.isLoggedIn = false;

    }

    this.dataService.getUser().subscribe(res => {

      if (res) {
        this.currentUser = res;
        this.isLoggedIn = true;
      }

    })



    this.getCountryList();

    this.selected_Date = 'Date';

    var hidWidth;
    var scrollBarWidths = 40;

    var widthOfList = function () {
      var itemsWidth = 0;
      $('.list li').each(function () {
        var itemWidth = $(this).outerWidth();
        itemsWidth += itemWidth;
      });
      return itemsWidth;
    };

    var widthOfHidden = function () {
      return (($('.wrapper-tabs').outerWidth()) - widthOfList() - getLeftPosi()) - scrollBarWidths;
    };

    var getLeftPosi = function () {
      return $('.list').position().left;
    };

    var reAdjust = function () {
      if (($('.wrapper-tabs').outerWidth()) < widthOfList()) {
        $('.scroller-right').show();
      }
      else {
        $('.scroller-right').hide();
      }

      if (getLeftPosi() < 0) {
        $('.scroller-left').show();
      }
      else {
        $('.item').animate({ left: "-=" + getLeftPosi() + "px" }, 'slow');
        $('.scroller-left').hide();
      }
    }

    reAdjust();

    $(window).on('resize', function (e) {
      reAdjust();
    });

    $('.scroller-right').click(function () {

      $('.scroller-left').fadeIn('slow');
      $('.scroller-right').fadeOut('slow');

      $('.list').animate({ left: "+=" + widthOfHidden() + "px" }, 'slow', function () {

      });
    });

    $('.scroller-left').click(function () {

      $('.scroller-right').fadeIn('slow');
      $('.scroller-left').fadeOut('slow');

      $('.list').animate({ left: "-=" + getLeftPosi() + "px" }, 'slow', function () {

      });
    });


  }

  closeFunction() {
    let closeBtn = document.getElementById("download");
    closeBtn.remove();
  }
  dashboard() {
    this.router.navigate(['/dashboard']);
  }

  mytickets() {
    this.router.navigate(['/mytickets']);
  }

  doLogout() {
    this.dataService.doLogOut();
    localStorage.clear();
    this.dataService.setUser(null);
    this.isLoggedIn = false;;
    this.currentUser = null
    this.router.navigate(['/']);

  }

  syncCity(val: any) {
    this.city_list = [];
    this.city_list = this.country_list.find((x: any) => x.countryId == val.countryId).city;
    if (!this.city_list.includes("Select City")) {
      this.city_list.unshift("Select City");
    }
    
    localStorage.setItem('apsession-country', JSON.stringify(this.country_list.find((x: any) => x.countryId == val.countryId)));
  }
  syncCategory(val: any) {
    this.category_list = [];
    this.category_list = this.country_list.find((x: any) => x.countryId == val.countryId).eventCategories;
    if (!this.city_list.includes("Select Category")) {
      this.category_list.unshift("Select Category");
    }
  }

  syncCategoryList() {
    this.categoryList = ["All", "This Weekend", "Next Weekend"]

    this.category_list.map((x: any, index) => {
      if (index != 0)
        this.categoryList.push(x)
    }
    )

  }


  forgotPassword() {
    if (this.dialog.openDialogs.length == 0) {
      let dialogRef = this.dialog.open(ForgotPasswordComponent, {
        width: '500px',
        panelClass: 'phonenumber-container'
      });
    }
  }
  getCountryList() {
    const body = {
      request_type: {
        request_name: "getCountrylist"
      },
      requestinfo: {
        searchname: ""
      }
    }

    this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
    this._MainService.postRequest(body).subscribe((results: any) => {
      this.loading.close()
      if (results.errorcode == '0') {

        let countryList = results.response.result;

        this.country_list = countryList
        let sCountry = localStorage.getItem("apsession-country") ? JSON.parse(localStorage.getItem("apsession-country")) : null;
        if (sCountry) {
          this.syncCity({ countryId: sCountry.countryId });
          this.syncCategory({ countryId: sCountry.countryId });
          this.selected_country = sCountry;

        }
        else {
          this.syncCity({ countryId: '1' });
          this.syncCategory({ countryId: '1' });
          this.selected_country = countryList[0];
        }


        // this.selected_city = this.city_list[1];
        // this.selected_category = this.category_list[1];

        this.syncCategoryList();
        this.searchEvents();
      }
      else {
        this.loading.close();
        this.showError(results.status, false);
      }
    }, err => {
      console.log(err);
      this.loading.close();
      this.showError("Unable to connect with server, please reload the page.", false);
    });
  }

  countryChange(event) {

    this.selected_country = this.country_list.find((x: any) => x.country == event);

    this.syncCity({ countryId: this.selected_country.countryId });
    this.syncCategory({ countryId: this.selected_country.countryId });
    this.selected_city = this.city_list[0];
    this.selected_category = this.category_list[0];
    this.syncCategoryList();
    this.searchEvents();

  }



  seeMoreEvents() {
    this.display_events_list = this.events_list;
  }

  clickedMarker(map_event, index: number, infowindow) {
    for (let i = 0; i < this.map_events.length; i++) {
      if (i == index) {
        map_event.marker_ic = "./assets/icons/location_yellow.png";
        this.map_events[i].marker_ic = "./assets/icons/location_yellow.png"
      }
      else {
        this.map_events[i].marker_ic = "./assets/icons/location.png"
      }
    }
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }

  closeMobile() {
    this.closeBtn.nativeElement.click()
  }
  openEventDetails(event_details) {

    this.dataService.setLocalStorageData("event_details", event_details);
    this.router.navigate(['/event-details', CryptoJS.AES.encrypt(event_details.id, "id").toString()]);
  }
  openLoginDialog() {
    if (this.dialog.openDialogs.length == 0) {
      let dialogRef = this.dialog.open(LoginComponent, {
        width: '600px',
        panelClass: 'login-container'
      });
      dialogRef.afterClosed().subscribe(result => {
        this.isLoggedIn = this.dataService.checkIfLogged();
      });
    }

  }

  reloadPage() {
    window.location.reload();
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  showSuccess(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-success'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }


  createEvent() {
    this.router.navigateByUrl('https://manager.apsession.com/#/event-setup');
  }


  getCordinates(val: any) {
    val.forEach((x: any) => {


      this._MainService.getGeoCode(x.venueaddress).pipe().subscribe(res => {

        console.log(res);
        if (res.results.length) {
          this.mapCordinates.push({
            latitude: res.results[0].geometry.location.lat,
            longitude: res.results[0].geometry.location.lng,
            name: x.name,
            venueaddress: x.venueaddress,
          });
        }
      }, err => {
        console.log("error coming..." + err);
      })
    })

    console.log('final cordinates', this.mapCordinates);
  }


  closeModal() {
    
    this.modalService.hide(1);
    document.body.classList.remove('modal-open');
    this.router.navigate(['/home']);

  }
  searchEvents() {


    this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
    const body = {
      request_type: {
        request_name: "allUpcomingEvents"
      },
      requestinfo: {
        eventName: this.user_search,
        parish: this.selected_city,
        genre: this.selected_category,
        country: this.selected_country.country,
        page_no: this.pageNumber
      }
    }
    this._MainService.postRequest(body).pipe(
      catchError(err => {
        this.loading.close();
        return err;
      })
    ).subscribe(res => {
      this.eventLoading = true;
      this.eventsData = [];
      // this.loading.close();
      if (res.errorcode == "0") {
        var data = res.response.result;
        // this.getCordinates(data);
        data.forEach((element: any, i: any) => {
          element.latitude = parseFloat(element.latitude)
          element.longitude = parseFloat(element.longitude)
          element.date = moment(element.date).format("ddd, MMM DD - YYYY");
          // if (i < 9) {
          this.eventsData.push(element);
          // }
        });

        this.loading.close();

        console.log(this.eventsData)
      }
      else {
        this.loading.close();
        this.eventsData = [];
      }
    })

  }

  tabChange(val: any, i: any) {
    this.matSelectedIndex = i;
    this.selected_category = val;
    this.searchEvents();
  }


  loadMoreEvents() {
    this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
    this.pageNumber += 1;
    const body = {
      request_type: {
        request_name: "allUpcomingEvents"
      },
      requestinfo: {
        eventName: this.user_search,
        parish: this.selected_city,
        genre: this.selected_category,
        country: this.selected_country.country,
        page_no: this.pageNumber
      }
    }
    this._MainService.postRequest(body).pipe(
      catchError(err => {
        this.loading.close();
        return err;
      })
    ).subscribe(res => {
      this.eventLoading = true;
      // this.loading.close();
      if (res.errorcode == "0") {
        var data = res.response.result;
        this.eventFinalIndex += data.length;
        // this.getCordinates(data);
        data.forEach((element: any, i: any) => {
          element.latitude = parseFloat(element.latitude)
          element.longitude = parseFloat(element.longitude)
          element.date = moment(element.date).format("ddd, MMM DD - YYYY");
          this.eventsData.push(element);
        });

        this.loading.close();

        console.log(this.eventsData)
      }
      else {
        this.loading.close();
        this.showError("No more events found", "Not found")
      }
    })

  }

  downloadTicket() {
    this.modalRef = this.modalService.show(TicketDownloader, { animated: true, class: "modal-xl" })
  }

}
