

import { Component, OnInit, NgZone, ElementRef, ViewChild, TemplateRef, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog, MatBottomSheet, MatBottomSheetRef } from '@angular/material';
import * as moment from 'moment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LoadingComponent } from '../loading/loading.component';
import { trigger, keyframes, animate, transition } from '@angular/animations';
import * as kf from '../keyframes';
import { SelectTicketsComponent } from '../select-tickets/select-tickets.component';
import { LoginComponent } from '../login/login.component';
import { SharingDialogComponent } from '../sharing-dialog/sharing-dialog.component';
import * as CryptoJS from 'crypto-js';
import { MainService } from '../main.service';
import { HeaderComponent } from 'src/app/app-dashboard/header/header.component';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css'],
  animations: [
    trigger('cardAnimator', [
      transition('* => wobble', animate(1000, keyframes(kf.wobble))),
      transition('* => swing', animate(1000, keyframes(kf.swing))),
      transition('* => jello', animate(1000, keyframes(kf.jello))),
      transition('* => zoomOutRight', animate(1000, keyframes(kf.zoomOutRight))),
      transition('* => slideOutLeft', animate(1000, keyframes(kf.slideOutLeft))),
      transition('* => rotateOutUpRight', animate(1000, keyframes(kf.rotateOutUpRight))),
      transition('* => flipOutY', animate(1000, keyframes(kf.flipOutY))),
    ])
  ]
})
@Injectable({
  providedIn: 'root'
})
export class EventDetailsComponent implements OnInit {
  modalRef: BsModalRef;
  event_id: any;
  event_details: any = {};
  disp_event_details: any = {};
  isShrunk: boolean;
  country_list: any = [];
  city_list: any = [];
  category_list: any = [];
  selected_country: any;
  selected_city: any;
  selected_category: any;
  user_search: any;
  events_list: any = [];
  rec_events_list: any = [];
  display_events_list: any = [];
  total_events: any;
  map_events: any = [];
  admission_array: any = [];
  previous;
  error_message: any;
  loading: any;
  login_response: any = {};
  isLoggedIn: any = false;
  isChangedByUser: any = false;

  // google maps zoom level
  zoom: number = 8;
  currentCountry: any;
  // initial center position for the map
  lat: number = 24.799448;
  lng: number = 120.979021;
  origin: any = { lat: 24.799448, lng: 120.979021 }
  destination: any = { lat: 24.799524, lng: 120.975017 }

  @ViewChild('coverBlock') elementView: ElementRef;
  showTktRight: any = false;
  currentUser: any;
  constructor(public route: ActivatedRoute, public dataService: DataService, private modalService: BsModalService, private router: Router, zone: NgZone, public snackBar: MatSnackBar, public dialog: MatDialog, private element: ElementRef, private bottomSheet: MatBottomSheet,
    private _MainService: MainService, private HeaderService: HeaderComponent) {

    this.isChangedByUser = false;

    this.disp_event_details.horizontalFlyer = "./assets/images/defaultimg.jpg";
    // let scrollUp = this.router.events.subscribe((path) => {
    //   this.element.nativeElement.scrollIntoView();
    // });
    this.route.params.subscribe(params => {

      if (params['event_id']) {
        this.event_id = CryptoJS.AES.decrypt(params['event_id'].toString(), "id").toString(CryptoJS.enc.Utf8);
      } else {
        this.router.navigate(['/']);
      }
    });

    this.login_response = this.dataService.getLocalStorageData("login_response");
    this.currentUser = this.login_response ? JSON.parse(this.login_response) : null
    if (this.currentUser) {
      this.dataService.setUser(this.currentUser)
    }
    if (!localStorage.getItem("event_details") && !this.event_id) {
      this.router.navigate(['/']);
    }

  }


  ngOnInit() {
    // 
    this.currentCountry = JSON.parse(localStorage.getItem('apsession-country'));

    if (this.currentCountry) {
      this.currentCountry = JSON.parse(localStorage.getItem('apsession-country'));
      this.event_details.currency = this.currentCountry.currency;
      this.disp_event_details.currency = this.currentCountry.currency;
      this.getEventDetailsById();
    }
    else {
      this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
      setTimeout(() => {
        this.loading.close();

        this.currentCountry = JSON.parse(localStorage.getItem('apsession-country'));
        this.event_details.currency = this.currentCountry.currency;
        this.disp_event_details.currency = this.currentCountry.currency;
        this.getEventDetailsById();
      }, 2000);
    }





  }

  animationState: string;

  openBottomSheet(): void {
    let sharingURL: any;
    sharingURL = "https://apsession.com/#/event-details/" + this.event_details.id;

    let sheedRef = this.bottomSheet.open(SharingDialogComponent, {
      data: { sharingURL: sharingURL },
      panelClass: 'sharing-container'
    });
  }

  openEventDetails(event_details) {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    this.dataService.setLocalStorageData("event_details", event_details);
    this.event_id = event_details.id;
    this.router.navigate(['/event-details', CryptoJS.AES.encrypt(event_details.id, "id").toString()]);
    this.getEventDetailsById();
  }




  getEventDetailsById() {
    this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
    let tmp_request = "eventDetail?id=" + this.event_id;

    this._MainService.getRequest(this.event_id).subscribe((results: any) => {
      this.loading.close();
      if (results.errorcode == 0) {
        this.events_list = results.result;
        this.event_details = this.events_list.find(x => x.pricetype == "presold");
        let sCountry = localStorage.getItem("apsession-country") ? JSON.parse(localStorage.getItem("apsession-country")) : null;

        if (this.event_details.currency != sCountry.currency) {
          console.log('Enter here =======================');
          this.HeaderService.changeCategoryData(this.event_details.country);
          this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/event-details', CryptoJS.AES.encrypt(this.event_details.id, "id").toString()]);
          });

        }


        this.dataService.setLocalStorageData("event_details", this.event_details);
        if (this.events_list.length > 0) {

          this.disp_event_details.buyerTicketFeeAmountPercentage = this.currentCountry.buyerTicketFeeAmountPercentage;
          this.disp_event_details.buyer_ticket_fee_dollar_amount = this.currentCountry.buyer_ticket_fee_dollar_amount;
          this.disp_event_details = this.events_list[0];
          if (!this.disp_event_details.horizontalFlyer) {
            this.disp_event_details.horizontalFlyer = "./assets/images/defaultimg.jpg";
          }
          if (!this.disp_event_details.flyer1large) {
            this.disp_event_details.flyer1large = "./assets/images/defaultimg.jpg";
          }
          if (!this.disp_event_details.flyer2large) {
            this.disp_event_details.flyer2large = "./assets/images/defaultimg.jpg";
          }
          let tmp_ticket_details: any = {}, that = this;


          this.disp_event_details.currency = this.event_details.currency;
          tmp_ticket_details.currency = this.event_details.currency;
          tmp_ticket_details.id = this.disp_event_details.id;
          tmp_ticket_details.name = this.disp_event_details.name;
          tmp_ticket_details.date = moment(this.disp_event_details.date).format("ddd, MMM DD YYYY");
          /* tmp_ticket_details.time = moment(this.disp_event_details.time, "hh:mm").format("hh:mm A"); */
          tmp_ticket_details.time = this.disp_event_details.time;
          tmp_ticket_details.parish = this.disp_event_details.parish;
          tmp_ticket_details.genre = this.disp_event_details.genre;
          tmp_ticket_details.country = this.disp_event_details.country;
          tmp_ticket_details.otherinfo = this.disp_event_details.otherinfo;
          tmp_ticket_details.latitude = parseFloat(this.disp_event_details.latitude) || 0;
          tmp_ticket_details.longitude = parseFloat(this.disp_event_details.longitude) || 0;
          tmp_ticket_details.venue = this.disp_event_details.venue;
          tmp_ticket_details.venueaddress = this.disp_event_details.venueaddress;
          tmp_ticket_details.horizontalFlyer = this.disp_event_details.horizontalFlyer;
          tmp_ticket_details.flyer1 = this.disp_event_details.flyer1;
          tmp_ticket_details.flyer2 = this.disp_event_details.flyer2;
          tmp_ticket_details.flyer1large = this.disp_event_details.flyer1large;
          tmp_ticket_details.flyer2large = this.disp_event_details.flyer2large;
          tmp_ticket_details.tmpFlyerImg1 = this.disp_event_details.flyer1large;
          tmp_ticket_details.tmpFlyerImg2 = this.disp_event_details.flyer2large;
          tmp_ticket_details.buyerTicketFeeAmountPercentage = this.disp_event_details.buyerTicketFeeAmountPercentage
          tmp_ticket_details.buyer_ticket_fee_dollar_amount = this.disp_event_details.buyer_ticket_fee_dollar_amount
          tmp_ticket_details.endDate = moment(this.disp_event_details.endDate).format("ddd, MMM DD YYYY");
          /* tmp_ticket_details.endTime = moment(this.disp_event_details.endTime, "hh:mm").format("hh:mm A"); */
          tmp_ticket_details.endTime = this.disp_event_details.endTime;
          tmp_ticket_details.marker_ic = this.disp_event_details.marker_ic = "./assets/icons/location.png";
          tmp_ticket_details.onlineTicketing = this.disp_event_details.onlineTicketing;
          tmp_ticket_details.guest_list = [];
          tmp_ticket_details.watchedby = this.disp_event_details.watchedby ? this.disp_event_details.watchedby.split(",") : [];
          tmp_ticket_details.watchedbyme = false;
          this.login_response = this.dataService.getLocalStorageData("login_response");
          if (this.login_response) {
            tmp_ticket_details.watchedby.map(function (arrayObj) {
              if (arrayObj === that.login_response.hostId) {
                tmp_ticket_details.watchedbyme = true;
              }
            });
          }

          if (this.disp_event_details.guest1) {
            tmp_ticket_details.guest_list.push({
              "guestName": this.disp_event_details.guestName1,
              "guest": this.disp_event_details.guest1,
              "guestl": this.disp_event_details.guest1l
            });
          }

          if (this.disp_event_details.guest2) {
            tmp_ticket_details.guest_list.push({
              "guestName": this.disp_event_details.guestName2,
              "guest": this.disp_event_details.guest2,
              "guestl": this.disp_event_details.guest2l
            });
          }

          if (this.disp_event_details.guest3) {
            tmp_ticket_details.guest_list.push({
              "guestName": this.disp_event_details.guestName3,
              "guest": this.disp_event_details.guest3,
              "guestl": this.disp_event_details.guest3l
            });
          }

          if (this.disp_event_details.guest4) {
            tmp_ticket_details.guest_list.push({
              "guestName": this.disp_event_details.guestName4,
              "guest": this.disp_event_details.guest4,
              "guestl": this.disp_event_details.guest4l
            });
          }

          if (this.disp_event_details.guest5) {
            tmp_ticket_details.guest_list.push({
              "guestName": this.disp_event_details.guestName5,
              "guest": this.disp_event_details.guest5,
              "guestl": this.disp_event_details.guest5l
            });
          }

          if (this.disp_event_details.guest6) {
            tmp_ticket_details.guest_list.push({
              "guestName": this.disp_event_details.guestName6,
              "guest": this.disp_event_details.guest6,
              "guestl": this.disp_event_details.guest6l
            });
          }


          this.disp_event_details.specialGuest = tmp_ticket_details.guest_list;

          tmp_ticket_details.tickets = [];

          let gnrl_tkt_prcs: any = {
            ticket_label: "General",
            quantity_name: "GENERAL",
            currency: this.disp_event_details.currency,
            ticket_prices: []
          };

          let vip_tkt_prcs: any = {
            ticket_label: "V.I.P",
            quantity_name: "VIP",
            currency: this.disp_event_details.currency,
            ticket_prices: []
          };

          let cus1_tkt_prcs: any = {
            ticket_label: "Custom #1",
            quantity_name: "CUSTOM1",
            currency: this.disp_event_details.currency,
            ticket_prices: []
          };

          let cus2_tkt_prcs: any = {
            ticket_label: "Custom #2",
            quantity_name: "CUSTOM2",
            currency: this.disp_event_details.currency,
            ticket_prices: []
          };

          let cus3_tkt_prcs: any = {
            ticket_label: "Custom #3",
            quantity_name: "CUSTOM3",
            currency: this.disp_event_details.currency,
            ticket_prices: []
          };

          let cus4_tkt_prcs: any = {
            ticket_label: "Custom #4",
            quantity_name: "CUSTOM4",
            currency: this.disp_event_details.currency,
            ticket_prices: []
          };

          let cus5_tkt_prcs: any = {
            ticket_label: "Custom #5",
            quantity_name: "CUSTOM5",
            currency: this.disp_event_details.currency,
            ticket_prices: []
          };


          let cus6_tkt_prcs: any = {
            ticket_label: "Custom #6",
            quantity_name: "CUSTOM6",
            currency: this.disp_event_details.currency,
            ticket_prices: []
          };


          let cus7_tkt_prcs: any = {
            ticket_label: "Custom #7",
            quantity_name: "CUSTOM7",
            currency: this.disp_event_details.currency,
            ticket_prices: []
          };

          let cus8_tkt_prcs: any = {
            ticket_label: "Custom #8",
            quantity_name: "CUSTOM8",
            currency: this.disp_event_details.currency,
            ticket_prices: []
          };


          for (let i = 0; i < this.events_list.length; i++) {
            if (this.events_list[i].general && this.events_list[i].general > 0) {//
              if (this.events_list[i].generalQtyLeft > 10) {
                gnrl_tkt_prcs.QtyLimit = 10;
              }
              else if (this.events_list[i].generalQtyLeft == -1) {
                gnrl_tkt_prcs.QtyLimit = 0;
              }
              else {
                gnrl_tkt_prcs.QtyLimit = this.events_list[i].generalQtyLeft;
              }

              gnrl_tkt_prcs.generalQtyLeft = this.events_list[i].generalQtyLeft;

              gnrl_tkt_prcs.Qty = 0;
              gnrl_tkt_prcs.description = this.events_list[i].generalDescription;
              if (this.events_list[i].pricetype == "gate") {
                gnrl_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].general,
                  "label": "Gate"
                });
              }
              if (this.events_list[i].pricetype == "presold") {
                gnrl_tkt_prcs.advancePrice = this.events_list[i].general;
                gnrl_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].general,
                  "label": "Presold"
                });
              }
              if (this.events_list[i].pricetype == "prime") {
                gnrl_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].general,
                  "label": "Prime"
                });
              }
            }

            if (this.events_list[i].vip && this.events_list[i].vip > 0) {
              if (this.events_list[i].vipQtyLeft > 10) {
                vip_tkt_prcs.QtyLimit = 10;
              }
              else if (this.events_list[i].vipQtyLeft == -1) {
                vip_tkt_prcs.QtyLimit = 0;
              }
              else {
                vip_tkt_prcs.QtyLimit = this.events_list[i].vipQtyLeft;
              }

              vip_tkt_prcs.vipQtyLeft = this.events_list[i].vipQtyLeft;


              vip_tkt_prcs.Qty = 0;
              vip_tkt_prcs.description = this.events_list[i].vipDescription;
              if (this.events_list[i].pricetype == "gate") {
                vip_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].vip,
                  "label": "Gate"
                });
              }
              if (this.events_list[i].pricetype == "presold") {
                vip_tkt_prcs.advancePrice = this.events_list[i].vip;
                vip_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].vip,
                  "label": "Presold"
                });
              }
              if (this.events_list[i].pricetype == "prime") {
                vip_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].vip,
                  "label": "Prime"
                });
              }
            }

            if (this.events_list[i].custom1_value && this.events_list[i].custom1_value > 0) {
              cus1_tkt_prcs.ticket_label = this.events_list[i].custom1_label;
              if (this.events_list[i].custom1Qty > 10) {
                cus1_tkt_prcs.QtyLimit = 10;
              }
              else if (this.events_list[i].custom1Qty == -1) {
                cus1_tkt_prcs.QtyLimit = 0;
              }
              else {
                cus1_tkt_prcs.QtyLimit = this.events_list[i].custom1QtyLeft;
              }

              cus1_tkt_prcs.custom1QtyLeft = this.events_list[i].custom1QtyLeft;

              cus1_tkt_prcs.Qty = 0;
              cus1_tkt_prcs.description = this.events_list[i].custom1Description;
              if (this.events_list[i].pricetype == "gate") {
                cus1_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom1_value,
                  "label": "Gate"
                });
              }
              if (this.events_list[i].pricetype == "presold") {
                cus1_tkt_prcs.advancePrice = this.events_list[i].custom1_value;
                cus1_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom1_value,
                  "label": "Presold"
                });
              }
              if (this.events_list[i].pricetype == "prime") {
                cus1_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom1_value,
                  "label": "Prime"
                });
              }
            }

            if (this.events_list[i].custom2_value && this.events_list[i].custom2_value > 0) {
              cus2_tkt_prcs.ticket_label = this.events_list[i].custom2_label;
              if (this.events_list[i].custom2Qty > 10) {
                cus2_tkt_prcs.QtyLimit = 10;
              }
              else if (this.events_list[i].custom2Qty == -1) {
                cus2_tkt_prcs.QtyLimit = 0;
              }
              else {
                cus2_tkt_prcs.QtyLimit = this.events_list[i].custom2QtyLeft;
              }
              cus2_tkt_prcs.custom2QtyLeft = this.events_list[i].custom2QtyLeft;

              cus2_tkt_prcs.Qty = 0;
              cus2_tkt_prcs.description = this.events_list[i].custom2Description;
              if (this.events_list[i].pricetype == "gate") {
                cus2_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom2_value,
                  "label": "Gate"
                });
              }
              if (this.events_list[i].pricetype == "presold") {
                cus2_tkt_prcs.advancePrice = this.events_list[i].custom2_value;
                cus2_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom2_value,
                  "label": "Presold"
                });
              }
              if (this.events_list[i].pricetype == "prime") {
                cus2_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom2_value,
                  "label": "Prime"
                });
              }
            }

            if (this.events_list[i].custom3_value && this.events_list[i].custom3_value > 0) {
              cus3_tkt_prcs.ticket_label = this.events_list[i].custom3_label;
              if (this.events_list[i].custom3Qty > 10) {
                cus3_tkt_prcs.QtyLimit = 10;
              }
              else if (this.events_list[i].custom3Qty == -1) {
                cus3_tkt_prcs.QtyLimit = 0;
              }
              else {
                cus3_tkt_prcs.QtyLimit = this.events_list[i].custom3QtyLeft;
              }

              cus4_tkt_prcs.custom3QtyLeft = this.events_list[i].custom3QtyLeft;

              cus3_tkt_prcs.Qty = 0;
              cus3_tkt_prcs.description = this.events_list[i].custom3Description;
              if (this.events_list[i].pricetype == "gate") {
                cus3_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom3_value,
                  "label": "Gate"
                });
              }
              if (this.events_list[i].pricetype == "presold") {
                cus3_tkt_prcs.advancePrice = this.events_list[i].custom3_value;
                cus3_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom3_value,
                  "label": "Presold"
                });
              }
              if (this.events_list[i].pricetype == "prime") {
                cus3_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom3_value,
                  "label": "Prime"
                });
              }
            }



            if (this.events_list[i].custom4_value && this.events_list[i].custom4_value > 0) {
              cus4_tkt_prcs.ticket_label = this.events_list[i].custom4_label;
              if (this.events_list[i].custom4Qty > 10) {
                cus4_tkt_prcs.QtyLimit = 10;
              }
              else if (this.events_list[i].custom4Qty == -1) {
                cus4_tkt_prcs.QtyLimit = 0;
              }
              else {
                cus4_tkt_prcs.QtyLimit = this.events_list[i].custom4QtyLeft;
              }

              cus4_tkt_prcs.custom4QtyLeft = this.events_list[i].custom4QtyLeft;

              cus4_tkt_prcs.Qty = 0;
              cus4_tkt_prcs.description = this.events_list[i].custom4Description;
              if (this.events_list[i].pricetype == "gate") {
                cus4_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom4_value,
                  "label": "Gate"
                });
              }
              if (this.events_list[i].pricetype == "presold") {
                cus4_tkt_prcs.advancePrice = this.events_list[i].custom4_value;
                cus4_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom4_value,
                  "label": "Presold"
                });
              }
              if (this.events_list[i].pricetype == "prime") {
                cus4_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom4_value,
                  "label": "Prime"
                });
              }
            }


            if (this.events_list[i].custom5_value && this.events_list[i].custom5_value > 0) {
              cus5_tkt_prcs.ticket_label = this.events_list[i].custom5_label;
              if (this.events_list[i].custom5Qty > 10) {
                cus5_tkt_prcs.QtyLimit = 10;
              }
              else if (this.events_list[i].custom5Qty == -1) {
                cus5_tkt_prcs.QtyLimit = 0;
              }
              else {
                cus5_tkt_prcs.QtyLimit = this.events_list[i].custom5QtyLeft;
              }

              cus5_tkt_prcs.custom5QtyLeft = this.events_list[i].custom5QtyLeft;

              cus5_tkt_prcs.Qty = 0;
              cus5_tkt_prcs.description = this.events_list[i].custom5Description;
              if (this.events_list[i].pricetype == "gate") {
                cus5_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom5_value,
                  "label": "Gate"
                });
              }
              if (this.events_list[i].pricetype == "presold") {
                cus5_tkt_prcs.advancePrice = this.events_list[i].custom5_value;
                cus5_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom5_value,
                  "label": "Presold"
                });
              }
              if (this.events_list[i].pricetype == "prime") {
                cus5_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom5_value,
                  "label": "Prime"
                });
              }
            }


            if (this.events_list[i].custom6_value && this.events_list[i].custom6_value > 0) {
              cus6_tkt_prcs.ticket_label = this.events_list[i].custom6_label;
              if (this.events_list[i].custom6Qty > 10) {
                cus6_tkt_prcs.QtyLimit = 10;
              }
              else if (this.events_list[i].custom6Qty == -1) {
                cus6_tkt_prcs.QtyLimit = 0;
              }
              else {
                cus6_tkt_prcs.QtyLimit = this.events_list[i].custom6QtyLeft;
              }

              cus6_tkt_prcs.custom6QtyLeft = this.events_list[i].custom6QtyLeft;

              cus6_tkt_prcs.Qty = 0;
              cus6_tkt_prcs.description = this.events_list[i].custom6Description;
              if (this.events_list[i].pricetype == "gate") {
                cus6_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom6_value,
                  "label": "Gate"
                });
              }
              if (this.events_list[i].pricetype == "presold") {
                cus6_tkt_prcs.advancePrice = this.events_list[i].custom6_value;
                cus6_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom6_value,
                  "label": "Presold"
                });
              }
              if (this.events_list[i].pricetype == "prime") {
                cus6_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom6_value,
                  "label": "Prime"
                });
              }
            }


            if (this.events_list[i].custom7_value && this.events_list[i].custom7_value > 0) {
              cus7_tkt_prcs.ticket_label = this.events_list[i].custom7_label;
              if (this.events_list[i].custom7Qty > 10) {
                cus7_tkt_prcs.QtyLimit = 10;
              }
              else if (this.events_list[i].custom7Qty == -1) {
                cus7_tkt_prcs.QtyLimit = 0;
              }
              else {
                cus7_tkt_prcs.QtyLimit = this.events_list[i].custom7QtyLeft;
              }

              cus7_tkt_prcs.custom7QtyLeft = this.events_list[i].custom7QtyLeft;

              cus7_tkt_prcs.Qty = 0;
              cus7_tkt_prcs.description = this.events_list[i].custom7Description;
              if (this.events_list[i].pricetype == "gate") {
                cus7_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom7_value,
                  "label": "Gate"
                });
              }
              if (this.events_list[i].pricetype == "presold") {
                cus7_tkt_prcs.advancePrice = this.events_list[i].custom7_value;
                cus7_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom7_value,
                  "label": "Presold"
                });
              }
              if (this.events_list[i].pricetype == "prime") {
                cus7_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom7_value,
                  "label": "Prime"
                });
              }
            }


            if (this.events_list[i].custom8_value && this.events_list[i].custom8_value > 0) {
              cus8_tkt_prcs.ticket_label = this.events_list[i].custom8_label;
              if (this.events_list[i].custom8Qty > 10) {
                cus8_tkt_prcs.QtyLimit = 10;
              }
              else if (this.events_list[i].custom8Qty == -1) {
                cus8_tkt_prcs.QtyLimit = 0;
              }
              else {
                cus8_tkt_prcs.QtyLimit = this.events_list[i].custom8QtyLeft;
              }

              cus8_tkt_prcs.custom8QtyLeft = this.events_list[i].custom8QtyLeft;

              cus8_tkt_prcs.Qty = 0;
              cus8_tkt_prcs.description = this.events_list[i].custom8Description;
              if (this.events_list[i].pricetype == "gate") {
                cus8_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom8_value,
                  "label": "Gate"
                });
              }
              if (this.events_list[i].pricetype == "presold") {
                cus8_tkt_prcs.advancePrice = this.events_list[i].custom8_value;
                cus8_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom8_value,
                  "label": "Presold"
                });
              }
              if (this.events_list[i].pricetype == "prime") {
                cus8_tkt_prcs.ticket_prices.push({
                  "price": this.events_list[i].custom8_value,
                  "label": "Prime"
                });
              }
            }

          }

          if (gnrl_tkt_prcs.ticket_prices.length > 0) {
            tmp_ticket_details.tickets.push({ ...gnrl_tkt_prcs, qty_left: this.event_details.generalQtyLeft });
          }

          if (vip_tkt_prcs.ticket_prices.length > 0) {
            tmp_ticket_details.tickets.push({ ...vip_tkt_prcs, qty_left: this.event_details.vipQtyLeft });
          }

          if (cus1_tkt_prcs.ticket_prices.length > 0) {
            tmp_ticket_details.tickets.push({ ...cus1_tkt_prcs, qty_left: this.event_details.custom1QtyLeft });
          }

          if (cus2_tkt_prcs.ticket_prices.length > 0) {
            tmp_ticket_details.tickets.push({ ...cus2_tkt_prcs, qty_left: this.event_details.custom2QtyLeft });
          }

          if (cus3_tkt_prcs.ticket_prices.length > 0) {
            tmp_ticket_details.tickets.push({ ...cus3_tkt_prcs, qty_left: this.event_details.custom3QtyLeft });
          }

          if (cus4_tkt_prcs.ticket_prices.length > 0) {
            tmp_ticket_details.tickets.push({ ...cus4_tkt_prcs, qty_left: this.event_details.custom4QtyLeft });
          }

          if (cus5_tkt_prcs.ticket_prices.length > 0) {
            tmp_ticket_details.tickets.push({ ...cus5_tkt_prcs, qty_left: this.event_details.custom5QtyLeft });
          }

          if (cus6_tkt_prcs.ticket_prices.length > 0) {
            tmp_ticket_details.tickets.push({ ...cus6_tkt_prcs, qty_left: this.event_details.custom6QtyLeft });
          }

          if (cus7_tkt_prcs.ticket_prices.length > 0) {
            tmp_ticket_details.tickets.push({ ...cus7_tkt_prcs, qty_left: this.event_details.custom7QtyLeft });
          }

          if (cus8_tkt_prcs.ticket_prices.length > 0) {
            tmp_ticket_details.tickets.push({ ...cus8_tkt_prcs, qty_left: this.event_details.custom8QtyLeft });
          }

          this.disp_event_details = tmp_ticket_details;
          this.disp_event_details.buyerTicketFeeAmountPercentage = this.currentCountry.buyerTicketFeeAmountPercentage
          this.disp_event_details.buyer_ticket_fee_dollar_amount = this.currentCountry.buyer_ticket_fee_dollar_amount
        }


        this.getRecommendedEvents();
      }
      else {
        this.error_message = results.status;
        this.loading.close();
        this.showError(results.status, false);
      }
    }, err => {
      console.log(err);
      this.loading.close();
      this.showError("Unable to connect with server, please reload the page.", false);
    });
  }



  getRecommendedEvents() {

    let body = { "request_type": { "request_name": "recommendedEvents" }, "requestinfo": { "country": this.currentCountry.country, "category": this.event_details.genre, "event_id": this.event_details.id } }
    this._MainService.postRequest(body).subscribe((results: any) => {
      if (results.errorcode == 0) {

        this.rec_events_list = results.response.result;
        this.total_events = results.response.result.length;
        this.display_events_list = [];
        this.rec_events_list.forEach((element, i) => {
          element.date = moment(element.date).format("ddd, MMM DD - YYYY");
          if (i < 9) {
            this.display_events_list.push(element);
          }
        });
        this.loading.close();
      }
      else {
        this.display_events_list = [];
        this.rec_events_list = [];
        this.total_events = this.rec_events_list.length;
        this.error_message = results.status;
        /* this.showError(results.status, false); */
        this.loading.close();
      }
    }, err => {
      console.log(err);
      this.showError("Unable to connect with server, please try again.", false);
      this.loading.close();
    });
  }

  getTickets() {

    if (this.dialog.openDialogs.length == 0) {
      let dialogRef = this.dialog.open(SelectTicketsComponent, {
        width: '600px',
        panelClass: 'login-container'
      });
      console.log(this.disp_event_details, "this.disp_event_details=====")

      dialogRef.componentInstance.event_details = JSON.parse(JSON.stringify(this.disp_event_details));
      dialogRef.afterClosed().subscribe(result => {
        this.login_response = this.dataService.getLocalStorageData("login_response");
        this.isLoggedIn = this.dataService.checkIfLogged();
      });
    }

  }

  addEventToWatched() {
    this.login_response = this.dataService.getLocalStorageData("login_response");
    if (this.login_response) {
      this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
      let request_data = new FormData();
      let body = { "request_type": { "request_name": "addevent" }, "requestinfo": { "event_id": this.event_details.id } }

      this._MainService.postRequest(body).subscribe((results: any) => {

        if (results.errorcode == 0) {
          this.disp_event_details.watchedbyme = true;
          this.loading.close();
          this.showSuccess(results.status, false);
        }
        else {
          this.showError(results.status, false);
          this.loading.close();
        }
      }, err => {
        console.log(err);
        this.showError("Unable to connect with server, please try again.", false);
        this.loading.close();
      });
    }
    else {
      if (this.dialog.openDialogs.length == 0) {
        let dialogRef = this.dialog.open(LoginComponent, {
          width: '600px',
          panelClass: 'login-container'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.login_response = result;
            this.isLoggedIn = this.dataService.checkIfLogged();
            this.addEventToWatched();
          }
        });
      }
    }
  }

  getDirection(lat, lng) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        window.open("http://maps.google.com/maps?saddr=" + position.coords.latitude + "," + position.coords.longitude + "&daddr=" + parseFloat(lat) + "," + parseFloat(lng), '_blank');
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
    /* let dialogRef = this.dialog.open(GetDirectionsComponent);
    dialogRef.componentInstance.destination = { lat: parseFloat(lat), lng: parseFloat(lng) } */
  }


  openLoginDialog() {
    if (this.dialog.openDialogs.length == 0) {
      let dialogRef = this.dialog.open(LoginComponent, {
        width: '600px',
        panelClass: 'login-container'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.login_response = result;
          this.isLoggedIn = this.dataService.checkIfLogged();
        }
      });
    }
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  showSuccess(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-success'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }




  reloadPage() {
    this.router.navigate(['/']);
  }


  dashboard() {
    this.router.navigate(['/dashboard']);
  }

  mytickets() {
    this.router.navigate(['/mytickets']);
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "img-modal" });
  }
  convertTime12to24 = (time12h) => {
    if (time12h) {
      const [time, modifier] = time12h.split(' ');

      let [hours, minutes] = time.split(':');

      if (hours === '12') {
        hours = '00';
      }

      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }

      return `${hours}:${minutes}`;
    }

  }

  convertsToMinutes(val: any) {
    let [hours, min, seconds] = val.split(':');
    let mins = (Number(hours) * 60) + Number(min);
    return mins;

  }
  getText() {


    let [eventDate, eventTime] = new Date(this.event_details.date + " " + this.convertTime12to24(this.event_details.time)).toLocaleString().split(', ');
    let [date, time] = new Date().toLocaleString().split(', ');


    if (moment(date).utc().isSame(moment(eventDate).utc())) {
      let mins = Number(this.convertsToMinutes(this.convertTime12to24(time))) + 60;
      let mins2 = Number(this.convertsToMinutes(this.convertTime12to24(eventTime)));
      if (mins <= mins2) {
        //1 hour 
        //just before one hour of start event
        return "Tickets";

      }
      else {
        return "Sales ended";

      }

    }
    else if (moment(date).utc().isBefore(moment(eventDate).utc())) {
      return "Tickets";
    }
    else {
      return "Sales ended";

    }
  }

  getText2() {
    // 2022-05-25T07:28:47Z
    let dat = this.event_details.date + " " + this.convertTime12to24(this.event_details.time) + ":00";
    let eventDate = moment(dat).utc();
    let currentDate = moment().utc();

    if (currentDate.add(1, "hour").isAfter(eventDate)) {
      //need to show sales ended in button
      return "Sales ended";
    }
    else {
      return "Tickets"
    }
  }

}
