import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-mobile',
  templateUrl: './login-mobile.component.html',
  styleUrls: ['./login-mobile.component.css']
})
export class LoginMobileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
