import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HOST } from './app.constants';
import { Observable } from "rxjs";
@Injectable({ providedIn: 'root' })

export class MainService {
    API_URL = 'https://api.apsession.com/v8/';
    // API_URL = 'https://stagingapi.apsession.com/v6/';
    google_key = 'AIzaSyBx_xvhQwRgWNiPAzxo3IL37qmgX1Ggepw';
    httpHeaders: any;
    constructor(
        private _HttpClient: HttpClient
    ) {

    }



    postRequest(body: any): Observable<any> {
        return this._HttpClient.post(`${this.API_URL}apsessionmobile/request`, body);
    }
    getRequest(id: any): Observable<any> {
        return this._HttpClient.get(`${this.API_URL}apsessionmobile/eventDetail?id=${id}`);
    }
    userPostRequest(body: any): Observable<any> {
        return this._HttpClient.post(`${this.API_URL}user/request`, body);
    }
    socialLogin(body): Observable<any> {
        return this._HttpClient.post(`${this.API_URL}user/request`, body);
    }
    forgotPassword(body): Observable<any> {
        return this._HttpClient.post(`${this.API_URL}user/request`, body);
    }
    resetPassword(body): Observable<any> {
        return this._HttpClient.post(`${this.API_URL}/user/request`, body);
    }
    getEventBySubDomain(body): Observable<any> {
        return this._HttpClient.post(`${this.API_URL}/apsessionmobile/request`, body)
    }


    getGeoCode(address: any): Observable<any> {
        return this._HttpClient.get(`https://maps.google.com/maps/api/geocode/json?address=${address}&sensor=false&key=AIzaSyBx_xvhQwRgWNiPAzxo3IL37qmgX1Ggepw`);
    }
}