

import { AuthenticationService } from './authentication.service';
import { HomeComponent } from './home/home.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MyticketsComponent } from './mytickets/mytickets.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignupComponent } from './signup/signup.component';
import { LoginMobileComponent } from './login-mobile/login-mobile.component';


export const AppRoutes = [
    { pathMatch: 'full', path: '', component: HomeComponent },
    { pathMatch: 'full', path: 'home', component: HomeComponent, canActivate: [AuthenticationService] },
    { pathMatch: 'full', path: 'mytickets', component: MyticketsComponent, canActivate: [AuthenticationService] },
    { pathMatch: 'full', path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationService] },
    { pathMatch: 'full', path: 'event-details', component: EventDetailsComponent},
    { pathMatch: 'full', path: 'signup', component: SignupComponent},
    { pathMatch: 'full', path: 'event-details/:event_id', component: EventDetailsComponent,  },
    {pathMatch :'full',path:'resetpassword/:token',component:ResetPasswordComponent,canActivate: [AuthenticationService]},
    {pathMatch :'full',path:'login-mobile',component:LoginMobileComponent,canActivate: [AuthenticationService]},
    { path: '**', component: PageNotFoundComponent }
];
