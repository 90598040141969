import { Component, OnInit, NgZone, Input, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../data.service';
import { MatSnackBar, MatDialog, MatSnackBarConfig } from '@angular/material';
import { LoginComponent } from 'src/app/login/login.component';
import { MainService } from 'src/app/main.service';
import { ForgotPasswordComponent } from 'src/app/forgot-password/forgot-password.component';

let isLoggedIn: boolean = false

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class HeaderComponent implements OnInit {
  @Input() currentUser;
  city_list: any = [];
  category_list: any = [];
  categoryList: any = [];
  country_list = []
  selected_country: any = {};
  selected_city: any = 'Select City';
  selected_category: any = 'Select Category';
  selected_Date: string = "Select Date";
  loading: any;
  setCountryList = [];

  constructor(public dataService: DataService, private _MainService: MainService, private router: Router, zone: NgZone, public snackBar: MatSnackBar, public dialog: MatDialog) {
  }
  ngAfterViewInit() {
    window.onscroll = function () { scrollFunction() }; 
    function scrollFunction() { 
      if (document.body.scrollTop > 60 || document.documentElement.scrollTop > 60) { 
        document.getElementById("mobile-menu")!.style.position = "fixed";
        document.getElementById("mobile-menu")!.style.zIndex = "999";      
        document.getElementById("mobile-menu")!.style.fontSize = "1rem"; 
        document.getElementById("mobile-menu")!.style.backgroundColor = "#ffea00"; 
        document.getElementById("mobile-menu")!.style.transition = "0.4s"; 
        document.getElementById("mobile-menu")!.style.boxShadow = "1px 1px 8px 1px rgba(102,102,102,.6)"; 
        // document.getElementById("mobile-menu")!.style.top = "9px"; 
        // document.getElementById("logo").style.width = "170px"; 
      } else if (document.body.scrollTop < 60 || document.documentElement.scrollTop < 60) { 
        // document.getElementById("logo").style.width = "180px";
        document.getElementById("mobile-menu")!.style.position = "fixed";
        document.getElementById("mobile-menu")!.style.zIndex = "999";    
        document.getElementById("mobile-menu")!.style.backgroundColor = "#ffea0000"; 
        document.getElementById("mobile-menu")!.style.fontSize = "1.05rem"; 
        document.getElementById("mobile-menu")!.style.boxShadow = "0px 0px 0px 0px rgba(0,0,0,0)"; 
        // document.getElementById("mobile-menu")!.style.top = "0px"; 
      } }
    }
  ngOnInit() {
    
    console.log('currentUser', this.currentUser);
    isLoggedIn = this.dataService.checkIfLogged()
    if (!this.currentUser) {
      this.dataService.getUser().subscribe(x => {
        this.currentUser = x;
      })

    }

    this.getCountryList();

  }

  mytickets() {
    if (!this.currentUser) {
      if (this.dialog.openDialogs.length == 0) {
        let dialogRef = this.dialog.open(LoginComponent, {
          width: '600px',
          panelClass: 'login-container'
        });
        dialogRef.afterClosed().subscribe(result => {
          dialogRef.close(null);
        });
      }
    }

    else {
      this.router.navigate(['/mytickets']);
    }
  }

  dashboard() {
    this.router.navigate(['/dashboard']);
  }

  home() {

    this.router.navigate(['/home']);
  }

  openLoginDialog() {
    if (this.dialog.openDialogs.length == 0) {
      let dialogRef = this.dialog.open(LoginComponent, {
        width: '600px',
        panelClass: 'login-container'
      });
      dialogRef.afterClosed().subscribe(result => {
        
        dialogRef.close(null);
      });
    }
  }
  reloadPage() {
    this.router.navigate(['/']);

  }

  forgotPassword() {
    if (this.dialog.openDialogs.length == 0) {
      let dialogRef = this.dialog.open(ForgotPasswordComponent, {
        width: '500px',
        panelClass: 'phonenumber-container'
      });
    }
  }


  createEvent() {
    if (!this.currentUser) {
      let dialogRef = this.dialog.open(LoginComponent, {
        width: '600px',
        panelClass: 'login-container'
      });
      dialogRef.afterClosed().subscribe(result => {
        
        dialogRef.close(null);
        // let data = this.dataService.checkIfLogged();
        // if (!data) {
        //   this.currentUser = null;
        // }
      });
    }
    else {
      this.router.navigate(['/newevent']);

    }

  }

  syncCity(val: any) {
    
    this.city_list = [];
    this.city_list = this.country_list.find((x: any) => x.countryId == val.countryId).city;
    this.city_list.unshift("Select City");
    localStorage.setItem('apsession-country', JSON.stringify(this.country_list.find((x: any) => x.countryId == val.countryId)));
  }
  syncCategory(val: any) {
    this.category_list = [];
    this.category_list = this.country_list.find((x: any) => x.countryId == val.countryId).eventCategories;
    this.category_list.unshift("Select Category");
  }

  syncCategoryList() {
    this.categoryList = ["All", "This Weekend", "Next Weekend"]

    this.category_list.map((x: any, index) => {
      if (index != 0)
        this.categoryList.push(x)
    }
    )

  }


  getCountryList() {
    const body = {
      request_type: {
        request_name: "getCountrylist"
      },
      requestinfo: {
        searchname: ""
      }
    }

    this._MainService.postRequest(body).subscribe((results: any) => {
      if (results.errorcode == '0') {

        let countryList = results.response.result;
        console.log(countryList,'country list');
        this.country_list = countryList;
        this.setCountryList = countryList;
        let sCountry = localStorage.getItem("apsession-country") ? JSON.parse(localStorage.getItem("apsession-country")) : null;
        if (sCountry) {
          this.syncCity({ countryId: sCountry.countryId });
          this.syncCategory({ countryId: sCountry.countryId });
          this.selected_country = sCountry;

        }
        else {
          this.syncCity({ countryId: '1' });
          this.syncCategory({ countryId: '1' });
          this.selected_country = countryList[0];
        }


        this.selected_city = this.city_list[1];
        this.selected_category = this.category_list[1];

        this.syncCategoryList();
      }
      else {
        this.loading.close();
        this.showError(results.status, false);
      }
    }, err => {
      console.log(err);
      this.loading.close();
      this.showError("Unable to connect with server, please reload the page.", false);
    });
  }


  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  showSuccess(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-success'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }


  countryChange(event) {
    
    this.selected_country = this.country_list.find((x: any) => x.country == event);
    this.syncCity({ countryId: this.selected_country.countryId });
    this.syncCategory({ countryId: this.selected_country.countryId });
    this.selected_city = this.city_list[0];
    this.selected_category = this.category_list[0];
    this.syncCategoryList();
    this.router.navigate(['/']);


  }


  doLogout() {
    this.dataService.doLogOut();
    localStorage.clear();
    this.dataService.setUser(null);
    this.router.navigate(['/']);
  }

  changeCategoryData(event){
    const body = {
      request_type: {
        request_name: "getCountrylist"
      },
      requestinfo: {
        searchname: ""
      }
    }

    this._MainService.postRequest(body).subscribe((results: any) => {
      if (results.errorcode == '0') {
        let countryList = results.response.result;
        this.selected_country = countryList.find((x: any) => x.country == event);
        localStorage.setItem('apsession-country',JSON.stringify(this.selected_country));
      }else {
        this.loading.close();
        this.showError(results.status, false);
      }
    }, err => {
      console.log(err);
      this.loading.close();
      this.showError("Unable to connect with server, please reload the page.", false);
    });
    
  }

}
