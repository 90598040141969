import { Component, OnInit, NgZone, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';
import { LoadingComponent } from '../../loading/loading.component';
import * as moment from 'moment';
import { ImageCroppedEvent } from 'ngx-image-cropper';
// 4:5
//1:1

import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { MainService } from 'src/app/main.service';

@Component({
  selector: 'app-newevent',
  templateUrl: './newevent.component.html',
  styleUrls: ['../../../assets/css/common.css']
})
export class NeweventComponent implements OnInit {
  imageChangedEvent: any = '';
  imageChangedEvent1: any = '';
  imageChangedEvent2: any = '';
  croppedImage: any = '';
  croppedImage1: any = '';
  croppedImage2: any = '';
  imagePath: any;
  imagePath1: any;
  imagePath2: any;
  horizontalflyer: any = '';
  horizontalflyer1: any = ''
  horizontalflyer2: any = '';


  loading: any;
  countrylist = [];
  countryinfo: any;
  card_process_fees = "";
  cityinfo = []
  eventCategories = []

  latitude: number;
  longitude: number;
  zoom: number;

  guest1path: any = "";
  croppedImageGuest1: any = '../../../assets/images/guest-icon.png';
  imageChangedEventGuest1: any = '';

  guest2path: any = ""
  croppedImageGuest2: any = '../../../assets/images/guest-icon.png';
  imageChangedEventGuest2: any = '';


  guest3path: any = ""
  croppedImageGuest3: any = '../../../assets/images/guest-icon.png';
  imageChangedEventGuest3: any = '';

  guest4path: any = ""
  croppedImageGuest4: any = '../../../assets/images/guest-icon.png';
  imageChangedEventGuest4: any = '';

  guest5path: any = ""
  croppedImageGuest5: any = '../../../assets/images/guest-icon.png';
  imageChangedEventGuest5: any = '';


  guest6path: any = ''
  croppedImageGuest6: any = '../../../assets/images/guest-icon.png';
  imageChangedEventGuest6: any = '';



  isGeneral = true;
  isVip = true;
  iscustom1Label = false
  iscustom2Label = false
  iscustom3Label = false

  venueaddress = ""
  venuename = ""


  constructor(public dataService: DataService, private _MainService: MainService, private router: Router, zone: NgZone, public snackBar: MatSnackBar, public dialog: MatDialog, private element: ElementRef) { }


  ngOnInit() {


    this.setCurrentLocation()
    this.getcountrylist()
  }

  draft(eventinfo) {
    this.eventinfo(eventinfo, 'draft')
  }
  publishevent(eventinfo) {
    this.eventinfo(eventinfo, 'review_pending')
  }

  async eventinfo(eventinfo, type) {
    console.log(type)
    if (typeof eventinfo.country == 'undefined' || eventinfo.country.trim() == '' || eventinfo.country == null) {
      this.showError('Select country !', false);
      return false;
    }


    if (typeof eventinfo.city == 'undefined' || eventinfo.city.trim() == '' || eventinfo.city == null) {
      this.showError('Select city!', false);
      return false;
    }


    if (typeof eventinfo.country == 'undefined' || eventinfo.country.trim() == '' || eventinfo.country == null) {
      this.showError('Select country!', false);
      return false;
    }


    if (typeof eventinfo.category == 'undefined' || eventinfo.category.trim() == '' || eventinfo.category == null) {
      this.showError('Select category!', false);
      return false;
    }


    if (typeof eventinfo.eventname == 'undefined' || eventinfo.eventname.trim() == '' || eventinfo.eventname == null) {
      this.showError('Enter eventname!', false);
      return false;
    }


    if (typeof eventinfo.eventname == 'undefined' || eventinfo.eventname.trim() == '' || eventinfo.eventname == null) {
      this.showError('Enter eventname!', false);
      return false;
    }

    if (typeof eventinfo.start_date == 'undefined' || eventinfo.start_date == '' || eventinfo.start_date == null) {
      this.showError('Enter start date!', false);
      return false;
    }

    if (typeof eventinfo.end_date == 'undefined' || eventinfo.end_date == '' || eventinfo.end_date == null) {
      this.showError('Enter end date!', false);
      return false;
    }
    var mStart = moment(eventinfo.start_date._d);
    var mEnd = moment(eventinfo.end_date._d);
    if (!mStart.isBefore(mEnd)) {
      this.showError('Enter valid event start and end date!', false);
      return false;
    }


    if (typeof eventinfo.venuename == 'undefined' || eventinfo.venuename.trim() == '' || eventinfo.venuename == null) {
      this.showError('Enter venue name!', false);
      return false;
    }

    if (typeof eventinfo.venueaddress == 'undefined' || eventinfo.venueaddress.trim() == '' || eventinfo.venueaddress == null) {
      this.showError('Enter venue address!', false);
      return false;
    }


    if (this.isGeneral && eventinfo.general_ticket_qty == '') {
      this.showError('Enter general quanity!', false);
      return false;
    }


    if (this.isVip && eventinfo.vip_ticket_qty == '') {
      this.showError('Enter vip quanity!', false);
      return false;
    }


    if (this.iscustom1Label && eventinfo.custom1_qty == '') {
      this.showError('Enter quanity !', false);
      return false;
    }


    if (this.iscustom2Label && eventinfo.custom2_qty == '') {
      this.showError('Enter quanity !', false);
      return false;
    }

    if (this.iscustom3Label && eventinfo.custom3_qty == '') {
      this.showError('Enter quanity !', false);
      return false;
    }


    this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
    let login_response = this.dataService.getLocalStorageData("login_response") ? JSON.parse(this.dataService.getLocalStorageData("login_response")) : null;

    // request_data.append('host_id', login_response.hostId);
    // request_data.append('email', login_response.email);
    // request_data.append('category', eventinfo.category);
    // request_data.append('city', eventinfo.city);
    // request_data.append('country', eventinfo.country);
    // request_data.append('custom1_qty', eventinfo.custom1_qty);
    // request_data.append('custom1_show_price', eventinfo.custom1_show_price);
    // request_data.append('custom1_ticket_price', eventinfo.custom1_ticket_price);
    // request_data.append('custom1description', eventinfo.custom1description);
    // request_data.append('custom1ticketname', eventinfo.custom1ticketname);
    // request_data.append('custom2_qty', eventinfo.custom2_qty);
    // request_data.append('custom2_show_price', eventinfo.custom2_show_price);
    // request_data.append('custom2_ticket_price', eventinfo.custom2_ticket_price);
    // request_data.append('custom2description', eventinfo.custom2description);
    // request_data.append('custom2ticketname', eventinfo.custom2ticketname);
    // request_data.append('custom3_qty', eventinfo.custom3_qty);
    // request_data.append('custom3_show_price', eventinfo.custom3_show_price);
    // request_data.append('custom3_ticket_price', eventinfo.custom3_ticket_price);
    // request_data.append('custom3description', eventinfo.custom3description);
    // request_data.append('custom3ticketname', eventinfo.custom3ticketname);
    // request_data.append('start_date', moment.utc(eventinfo.start_date._d).format('YYYY-MM-DD hh:mm A'));
    // request_data.append('end_date', moment.utc(eventinfo.end_date._d).format('YYYY-MM-DD hh:mm A'));
    // request_data.append('eventname', eventinfo.eventname);
    // request_data.append('general_show_price', eventinfo.general_show_price);
    // request_data.append('general_ticket_price', eventinfo.general_ticket_price);
    // request_data.append('general_ticket_qty', eventinfo.general_ticket_qty);
    // request_data.append('generalticket', eventinfo.generalticket);

    // request_data.append('guestname1', eventinfo.guestname1);
    // request_data.append('guestname2', eventinfo.guestname2);
    // request_data.append('guestname3', eventinfo.guestname3);
    // request_data.append('guestname4', eventinfo.guestname4);
    // request_data.append('guestname5', eventinfo.guestname5);
    // request_data.append('guestname6', eventinfo.guestname6);
    // request_data.append('guestname6', eventinfo.guestname6);
    // request_data.append('venuename', eventinfo.venuename);
    // request_data.append('venueaddress', eventinfo.venueaddress);
    // request_data.append('vip_show_price', eventinfo.vip_show_price);
    // request_data.append('vip_ticket_price', eventinfo.vip_ticket_price);
    // request_data.append('vip_ticket_qty', eventinfo.vip_ticket_qty);
    // request_data.append('vipticket', eventinfo.vipticket);

    // request_data.append('latitude', this.latitude.toString());
    // request_data.append('longitude', this.longitude.toString());
    // request_data.append('eventdetails', eventinfo.eventdetails);
    // request_data.append('post_visibility', type);

    // if (!this.isGeneral && !this.isVip && !this.iscustom1Label && !this.iscustom2Label && !this.iscustom3Label) {
    //   request_data.append('isfree', '1')
    // } else {
    //   request_data.append('isfree', '0')
    // }



    const body = {
      "request_type": {
        "request_name": "create_event"
      },
      "requestinfo": {
        "host_id": login_response.hostId,
        "host_email": login_response.email,
        "eventname": eventinfo.eventname,
        "otherinfo": "",
        "startdate": moment(eventinfo.start_date).format("YYYY-MM-DD"),
        "starttime": moment(eventinfo.start_date).format("HH:MM A"),
        "enddate": moment(eventinfo.end_date).format("YYYY-MM-DD"),
        "endtime": moment(eventinfo.end_datee).format("HH:MM A"),
        "country": eventinfo.country,
        "city": eventinfo.city,
        "category": eventinfo.category,
        "venue": eventinfo.venuename,
        "venueaddress": eventinfo.venueaddress,
        "isfree": (!this.isGeneral && !this.isVip && !this.iscustom1Label && !this.iscustom2Label && !this.iscustom3Label) ? '1' : '2',
        "specialguest": "",
        "horizontalflyer": await this.uploadImagesToServer(this.imagePath),
        "horizontalflyer1": await this.uploadImagesToServer(this.imagePath1),
        "horizontalflyer2": await this.uploadImagesToServer(this.imagePath2),
        "general_door_price": eventinfo.general_show_price,
        "general_advance_price": eventinfo.general_ticket_price,
        "general_qty": eventinfo.general_ticket_qty,
        "general_desc": eventinfo.generalticket,
        "vip_door_price": eventinfo.vip_show_price,
        "vip_advance_price": eventinfo.vip_ticket_price,
        "vip_qty": eventinfo.vip_ticket_qty,
        "vip_desc": eventinfo.vipticket,
        "custom1_label": eventinfo.custom1ticketname,
        "custom1_door_price": eventinfo.custom1_show_price,
        "custom1_advance_price": eventinfo.custom1_ticket_price,
        "custom1_qty": eventinfo.custom2_qty,
        "custom1_description": eventinfo.custom1description,
        "custom2_label": eventinfo.custom1ticketname,
        "custom2_door_price": eventinfo.custom1_show_price,
        "custom2_advance_price": eventinfo.custom1_ticket_price,
        "custom2_qty": eventinfo.custom2_qty,
        "custom2_description": eventinfo.custom1description,
        "custom3_label": eventinfo.custom1ticketname,
        "custom3_door_price": eventinfo.custom1_show_price,
        "custom3_advance_price": eventinfo.custom1_ticket_price,
        "custom3_qty": eventinfo.custom2_qty,
        "custom3_description": eventinfo.custom1description,
        "custom4_label": eventinfo.custom1ticketname,
        "custom4_door_price": eventinfo.custom1_show_price,
        "custom4_advance_price": eventinfo.custom1_ticket_price,
        "custom4_qty": eventinfo.custom2_qty,
        "custom4_description": eventinfo.custom1description,
        "custom5_label": eventinfo.custom1ticketname,
        "custom5_door_price": eventinfo.custom1_show_price,
        "custom5_advance_price": eventinfo.custom1_ticket_price,
        "custom5_qty": eventinfo.custom2_qty,
        "custom5_description": eventinfo.custom1description,
        "custom6_label": eventinfo.custom1ticketname,
        "custom6_door_price": eventinfo.custom1_show_price,
        "custom6_advance_price": eventinfo.custom1_ticket_price,
        "custom6_qty": eventinfo.custom2_qty,
        "custom6_description": eventinfo.custom1description,
        "custom7_label": eventinfo.custom1ticketname,
        "custom7_door_price": eventinfo.custom1_show_price,
        "custom7_advance_price": eventinfo.custom1_ticket_price,
        "custom7_qty": eventinfo.custom2_qty,
        "custom7_description": eventinfo.custom1description,
        "custom8_label": eventinfo.custom1ticketname,
        "custom8_door_price": eventinfo.custom1_show_price,
        "custom8_advance_price": eventinfo.custom1_ticket_price,
        "custom8_qty": eventinfo.custom2_qty,
        "custom8_description": eventinfo.custom1description,
        "ticket_sales_status": "1",
        "guest_name1": eventinfo.guestname1,
        "guest_name2": eventinfo.guestname2,
        "guest_name3": eventinfo.guestname3,
        "guest_name4": eventinfo.guestname4,
        "guest_name5": eventinfo.guestname5,
        "guest_name6": eventinfo.guestname6,
        "guest1l": await this.uploadImagesToServer(this.guest1path),
        "guest2l": await this.uploadImagesToServer(this.guest2path),
        "guest3l": await this.uploadImagesToServer(this.guest3path),
        "guest4l": await this.uploadImagesToServer(this.guest4path),
        "guest5l": await this.uploadImagesToServer(this.guest5path),
        "guest6l": await this.uploadImagesToServer(this.guest6path),
        "latitude": await this.uploadImagesToServer(this.latitude.toString()),
        "longitude": await this.uploadImagesToServer(this.longitude.toString())
      }
    }


    console.log(body)
    this._MainService.postRequest(body).subscribe((results: any) => {
      if (results.errorcode == 0) {
        this.loading.close();
        this.showSuccess(results.status, false)
        this.router.navigate(['/']);
      }
      else {
        this.loading.close();
        this.showError(results.status, false);
      }
    }, err => {
      console.log(err);
      this.showError("Unable to connect with server, please try again.", false);

    });
  }
  getcountrylist() {
    
    this.dataService.getData("getCountrylist").subscribe((results: any) => {

      if (results.errorcode == 0) {
        console.log(results)

        if (results.result.length > 0) {
          this.countrylist = results.result;
          this.countryinfo = this.countrylist[0]
        }

        console.log(this.countrylist);

      } else {
        this.showError(results.status, false);
      }
    }, err => {
      console.log(err);
      this.showError("Unable to connect with server, please try again.", false);
      this.loading.close();
    });
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  changecountry(event) {
    for (let element of this.countrylist) {
      if (element.country == event) {
        this.countryinfo = element
        this.card_process_fees = element.hostServiceFeePercentage + "% + $" + element.hostConvenienceFeeDollarAmount + " " + element.currency
        this.cityinfo = element.city;

        this.eventCategories = element.eventCategories
        break;
      }
    }
  }


  showSuccess(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-success'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }

  public handleAddressChange(address: Address) {
    this.latitude = address.geometry.location.lat()
    this.longitude = address.geometry.location.lng()
    this.venuename = address.name
    this.venueaddress = address.formatted_address

  }
  modalOk(event) {
    console.log('event', event);
    console.log('this', this);
  }

  generalClick() {
    if (this.isGeneral) {
      this.isGeneral = false;
    }
  }

  vipClick() {
    if (this.isVip) {
      this.isVip = false;
    }
  }

  custom3Click() {
    if (this.iscustom3Label) {
      this.iscustom3Label = false
    }
  }

  custom2Click() {
    if (this.iscustom2Label) {
      this.iscustom2Label = false
    }
  }

  custom1Click() {
    if (this.iscustom1Label) {
      this.iscustom1Label = false
    }


  }

  addcustomBtn() {

    if (!this.iscustom1Label) {
      this.iscustom1Label = true
    } else if (!this.iscustom2Label) {
      this.iscustom2Label = true
    } else if (!this.iscustom3Label) {
      this.iscustom3Label = true
    }

  }


  mytickets() {
    this.router.navigate(['/mytickets']);
  }

  dashboard() {
    this.router.navigate(['/dashboard']);
  }

  home() {
    this.router.navigate(['/home']);
  }

  logout() {
    this.dataService.doLogOut();
    this.router.navigate(['/']);
  }

  cancelImage1() {
    this.croppedImage1 = ''
    this.imageChangedEvent1 = ''
    this.imagePath1 = ''

  }

  horizontalflyerCrop1() {
    this.horizontalflyer1 = this.croppedImage1
    this.croppedImage1 = ''
    this.imageChangedEvent1 = ''
  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    // this.imagePath = this.dataURItoBlob(event.base64);
    this.imagePath = event.base64;
    this.croppedImage = event.base64;
  }

  fileChangeEvent1(event: any): void {
    this.imageChangedEvent1 = event;
  }

  imageCropped1(event: ImageCroppedEvent) {
    // this.imagePath1 = this.dataURItoBlob(event.base64);
    this.imagePath1 = event.base64;
    this.croppedImage1 = event.base64;
  }


  fileChangeEvent2(event: any): void {
    this.imageChangedEvent2 = event;
  }

  imageCropped2(event: ImageCroppedEvent) {
    // this.imagePath2 = this.dataURItoBlob(event.base64);
    this.imagePath2 = event.base64;
    this.croppedImage2 = event.base64;
  }

  dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/png'
    });
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }


  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  cancelImage() {
    this.croppedImage = ''
    this.imageChangedEvent = ''
    this.horizontalflyer = ''
    this.imagePath = ''
  }


  horizontalflyerCrop() {
    this.horizontalflyer = this.croppedImage
    this.croppedImage = ''
    this.imageChangedEvent = ''
  }

  cancelImage2() {
    this.croppedImage2 = ''
    this.imageChangedEvent2 = ''
    this.imagePath2 = ''

  }

  horizontalflyerCrop2() {
    this.horizontalflyer2 = this.croppedImage2
    this.croppedImage2 = ''
    this.imageChangedEvent2 = ''
  }


  fileChangeEventGuest1(event: any): void {
    this.imageChangedEventGuest1 = event;
  }
  imageCroppedGuest1(event: ImageCroppedEvent) {
    

    // this.guest1path = this.dataURItoBlob(event.base64);
    this.guest1path = event.base64;
    // this.dataService.uploadFile(event.base64).then(res=>{
    //   console.log("1st",res)

    // })
    this.croppedImageGuest1 = event.base64;
  }

  guestcancelImage1() {
    this.croppedImageGuest1 = './../../assets/images/guest-icon.png'
    this.imageChangedEventGuest1 = ''
    this.guest1path = ""
  }

  guestCrop1() {
    this.imageChangedEventGuest1 = ''
  }


  fileChangeEventGuest2(event: any): void {
    this.imageChangedEventGuest2 = event;
  }
  imageCroppedGuest2(event: ImageCroppedEvent) {
    // this.guest2path = this.dataURItoBlob(event.base64);
    this.guest2path = event.base64;
    this.croppedImageGuest2 = event.base64;
  }

  guestcancelImage2() {
    this.croppedImageGuest2 = "./../../assets/images/guest-icon.png"
    this.imageChangedEventGuest2 = ''
    this.guest2path = ''
  }

  guestCrop2() {
    this.imageChangedEventGuest2 = ''
  }



  fileChangeEventGuest3(event: any): void {
    this.imageChangedEventGuest3 = event;
  }
  imageCroppedGuest3(event: ImageCroppedEvent) {
    // this.guest3path = this.dataURItoBlob(event.base64);
    this.guest3path = event.base64;
    this.croppedImageGuest3 = event.base64;
  }

  guestcancelImage3() {
    this.croppedImageGuest3 = './../../assets/images/guest-icon.png'
    this.imageChangedEventGuest3 = ''
    this.guest3path = ''
  }

  guestCrop3() {
    this.imageChangedEventGuest3 = ''
  }


  fileChangeEventGuest4(event: any): void {
    this.imageChangedEventGuest4 = event;
  }
  imageCroppedGuest4(event: ImageCroppedEvent) {
    // this.guest4path = this.dataURItoBlob(event.base64);
    this.guest4path = event.base64;
    this.croppedImageGuest4 = event.base64;
  }

  guestcancelImage4() {
    this.croppedImageGuest4 = './../../assets/images/guest-icon.png'
    this.imageChangedEventGuest4 = ''
    this.guest3path = ''
  }

  guestCrop4() {
    this.imageChangedEventGuest4 = ''
  }


  fileChangeEventGuest5(event: any): void {
    this.imageChangedEventGuest5 = event;
  }
  imageCroppedGuest5(event: ImageCroppedEvent) {
    // this.guest5path = this.dataURItoBlob(event.base64);
    this.guest5path = event.base64;
    this.croppedImageGuest5 = event.base64;
  }

  guestcancelImage5() {
    this.croppedImageGuest5 = './../../assets/images/guest-icon.png'
    this.imageChangedEventGuest5 = ''
    this.guest5path = ''
  }

  guestCrop5() {
    this.imageChangedEventGuest5 = ''
  }


  fileChangeEventGuest6(event: any): void {
    this.imageChangedEventGuest6 = event;
  }
  imageCroppedGuest6(event: ImageCroppedEvent) {
    // this.guest6path = this.dataURItoBlob(event.base64);
    this.guest6path = event.base64;
    this.croppedImageGuest6 = event.base64;
  }

  guestcancelImage6() {
    this.croppedImageGuest6 = './../../assets/images/guest-icon.png'
    this.imageChangedEventGuest6 = ''
    this.guest6path = ""
  }

  guestCrop6() {
    this.imageChangedEventGuest6 = ''
  }


  uploadImagesToServer(file: any) {
    if (file) {
      return this.dataService.uploadFile(file).then(x => x);
    }
    else {
      return "";
    }
  }

}
