
import { Component, OnInit, NgZone, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';
import { LoadingComponent } from '../../loading/loading.component';


@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.component.html',
  styleUrls: [ '../../../assets/css/common.css']
})
export class CheckinComponent implements OnInit {

  events: any = new Object()
  error_message: any;
  loading: any;
  transaction_list: any = [];
  servicefee = '0.00';
  totaluserArrived:number=0;
  clamied_ticketList:any =[];
  ticketDetails:any={}


  constructor(public dataService: DataService, private router: Router, zone: NgZone, public snackBar: MatSnackBar, public dialog: MatDialog, private element: ElementRef) { }
  ngOnInit() {
    if(!this.dataService.checkIfLogged()){
      this.router.navigate(['/']);
    }
    
   this. events = this.dataService.getLocalStorageData("event");

    this.getcheckins()
  }


  getcheckins() {

    let login_response = this.dataService.getLocalStorageData("login_response");
    console.log(event);
    this.loading = this.dialog.open(LoadingComponent, { disableClose: true });

    this.dataService.getData("checkins?userId=" + login_response.hostId + "&event_id=" + this.events.id + "").subscribe((results: any) => {

      if (results.errorcode == 0) {
        if (results.response.result.length > 0) {
          this.ticketDetails = results.response.result[0];
        }

        console.log(this.ticketDetails);
        this.totaluserArrived = this.getcalValue(results.response.totalClaimed_Tickets, results.response.totalpurcahsed_tickets);
        this.clamied_ticketList = results.response.clamied_tickets;
        this.loading.close();
      }
      else {
        this.showError(results.status, false);
        this.loading.close();
      }
    }, err => {
      console.log(err);
      this.showError("Unable to connect with server, please try again.", false);
      this.loading.close();
    });
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

 

  getcalValue(val1: any, val2: any) {

    if (!val1 || parseInt(val1)==0){
      return 0;
    } else if (val1 && parseInt(val1)==parseInt(val2)){
      return 100;
    }else{
      return (parseInt(val1) / parseInt(val2)*100)
    }
  }


}
