/* ******************************************************************************
OptimumBrew Technology Pvt. Ltd.

Title:            Forgot Password TS
Since:            21 Sept, 2018
Author:           Umesh Patadiya
Email:            umeshpatadiya1995@gmail.com

****************************************************************************** */

import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog, MatDialogRef } from '@angular/material';
import { LoadingComponent } from '../loading/loading.component';
import { MainService } from '../main.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  user_detail: any = {};
  loading: any;

  constructor(public dataService: DataService, private _MainService: MainService, public snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<ForgotPasswordComponent>) {
  }

  ngOnInit() {
  }

  resetPassword(user_detail) {
    if (typeof user_detail.email == "undefined" || user_detail.email.trim() == "" || user_detail.email == null) {
      this.showError("Please enter email", false);
      return false;
    }
    else if (!this.dataService.validateEmail(user_detail.email)) {
      this.showError("Please enter a valid email", false);
      return false;
    }
    else {
      this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
      let body = {"request_type":{"request_name":"resetPasswordSendLink"},"requestinfo":{"requestMethod":"emailMethod","email":user_detail.email.trim()}}

      this._MainService.forgotPassword(body).subscribe((results: any) => {
        if (results.errorcode == 0) {
          this.loading.close();
          this.showSuccess(results.message, false);
          this.dialogRef.close();
        }
        else {
          this.showError("email not found", false);
          this.loading.close();
        }
      }, err => {
        console.log(err);
        this.showError("Unable to connect with server, please try again.", false);
        this.loading.close();
      });
    }
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  showSuccess(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-success'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 6000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }


}
