/* ******************************************************************************
OptimumBrew Technology Pvt. Ltd.

Title:            Apsession v2
File:             App Component TS
Since:            21 Sept, 2018
Author:           Umesh Patadiya
Email:            umeshpatadiya1995@gmail.com

****************************************************************************** */
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DataService } from './data.service';
import { MainService } from './main.service';
import * as CryptoJS from 'crypto-js';
import { filter } from 'rxjs/operators';

declare const gtag: Function;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  constructor(private dataService: DataService, private _Router: Router, private _MainService: MainService) {
    var full = window.location.host
    var parts = full.split('.')
    var subDomain = parts[0];
    console.log(subDomain)

    let body = {
      "request_type": {
        "request_name": "eventnameexits"
      },
      "requestinfo": {
        "name": subDomain
      }
    }

    if (subDomain) {
      this._MainService.getEventBySubDomain(body).subscribe(x => {
        console.log("resp subdomain=", x)
        if (x.errorcode == 0) {
          this._Router.navigate(['/event-details', CryptoJS.AES.encrypt(x.response.id, "id").toString()]);
        }
      })
    }


    // this._Router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe((event: NavigationEnd) => {

    //   gtag('event', 'page_view', {
    //     page_path: event.urlAfterRedirects
    //   })
      
    // })


  }
}
