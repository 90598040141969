import { Component, OnInit, NgZone, ElementRef, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';
import { MouseEvent } from '@agm/core';
import * as moment from 'moment';
import { LoadingComponent } from '../loading/loading.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { MainService } from '../main.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { TicketDownloader } from '../ticket-downloader/ticket-downloader.component';
import { ThrowStmt } from '@angular/compiler';
declare var $;

@Component({
  selector: 'app-mytickets',
  templateUrl: './mytickets.component.html',
  styleUrls: ['./mytickets.component.css', '../../assets/css/common.css']
})

export class MyticketsComponent implements OnInit, AfterViewInit {
  @ViewChild('ticketModal') ticketModal: any;

  // @ViewChild(TicketDownloader) TicketDownloader: TicketDownloader;
  modalRef: BsModalRef;
  error_message: any;
  loading: any;
  barcode_val: any;
  tickets_list: any = [];
  tickets: any = new Object();
  isLoggedIn: boolean = false
  currentUser: any;
  currentTicket: any;
  currentYear = new Date().getFullYear()
  selected_country = ""
  country_list = []
  upcomingCount = 0
  oldCount = 0
  isMobile = false;
  mobileTicketSelected = false
  dd: any;
  appLink: any;
  type: any = "upcoming";
  pageNumber = 2;
  isloading: boolean = false
  disable: boolean = false
  constructor(public dataService: DataService, public _BsModalService: BsModalService, private _ElementRef: ElementRef, private _ActivatedRoute: ActivatedRoute, private _MainService: MainService, private modalService: BsModalService, private router: Router, zone: NgZone, public snackBar: MatSnackBar, public dialog: MatDialog, private element: ElementRef, private _TicketDownloader: TicketDownloader) {
    // this.dd=this._ElementRef.nativeElement.innerHTML;
    // console.log("eleref=",dd)
  }
  ngAfterViewInit(): void {

    //jquery code
    $(document).ready(function () {

    });
  }

  ngOnInit() {

    var data: any = this._ActivatedRoute.snapshot.params;
    if (data.state == "mytickets") {
      this.modalRef = this.modalService.show(this.ticketModal, { animated: true, keyboard: false, backdrop: 'static', class: "ticket-modal" });
    }

    this.isMobile = this.dataService.isMobileDevice()

    if (this.dataService.checkOS() == "IOS") {
      this.appLink = "https://apps.apple.com/app/id802888811";
    }
    else if (this.dataService.checkOS() == "ANDROID") {
      this.appLink = "https://play.google.com/store/apps/details?id=com.apsession.air.apsessionuser";
    } else {
      this.appLink = "https://play.google.com/store/apps/details?id=com.apsession.air.apsessionuser";
    }

    this.getallmytickets("upcoming")

    const body = {
      request_type: {
        request_name: "getMyTickets"
      },
      requestinfo: { "page_no": 1, "type": 'old' }
    }

    this._MainService.postRequest(body).subscribe((res: any) => {

      if (res.errorcode == 0) {
        this.oldCount = res.response.result.length;
      } else {
        this, this.oldCount = 0;
      }
    })

  }

  closeFunction() {
    let closeBtn = document.getElementById("download");
    closeBtn.remove();
  }
  openLoginDialog() {

  }
  createEvent() {

  }
  closeModal() {

    // this.modalRef.hide();
    this._BsModalService.hide(1)
    document.body.classList.remove('modal-open');
    this.router.navigate(['/mytickets']);
  }

  loadMore() {
    this.isloading = true
    let login_response = this.dataService.getLocalStorageData("login_response");
    this.currentUser = login_response ? JSON.parse(login_response) : null;
    this.isLoggedIn = JSON.parse(login_response) ? true : false;


    let request_data = new FormData();
    request_data.append("id", login_response.hostId);
    const body = {
      request_type: {
        request_name: "getMyTickets"
      },
      requestinfo: { "page_no": this.pageNumber, "type": this.type }
    }

    this._MainService.postRequest(body).subscribe((res: any) => {
      this.isloading = false;
      console.log(res);
      if (res.errorcode == 0) {
        this.pageNumber += 1;
        let tickets_list = res.response.result;
        tickets_list.forEach(x => {
          let img = x.barcode.replace("https://s3.amazonaws.com/apsession/", "https://apsession.s3.amazonaws.com/")
          this.convertImg(img).then(y => {
            x.barcode = y
          });
        })

        this.tickets_list = [...this.tickets_list, ...tickets_list]
        this.type == "upcoming" ? this.upcomingCount = this.tickets_list.length : this.oldCount = this.tickets_list.length;
        // this.currentTicket = res.response.result[0];
        // this.dataService.getBase64ImageFromUrl("https://boookoo.s3.us-east-2.amazonaws.com/profile/1641985988.png").then(y=>{
        //   console.log(y)

        // })
        
        setTimeout(() => {
          let id="tick" + this.tickets_list.length
          var objDiv = document.getElementById(id);
          console.log()
          objDiv.scrollTop = objDiv.scrollHeight;
          document.getElementById("tick" + this.tickets_list.length).scrollIntoView({ behavior: "smooth" })
        }, 600);

        this.currentTicket.index = 0;

        // this.loading.close();
      }
      else {
        this.disable = true
      }
    }, err => {
      console.log(err);
      this.showError("Unable to connect with server, please try again.", false);
      this.loading.close();
    });
  }

  getallmytickets(type) {

    console.log(this.tickets, "Blank");
    let login_response = this.dataService.getLocalStorageData("login_response");
    this.currentUser = login_response ? JSON.parse(login_response) : null;
    this.isLoggedIn = JSON.parse(login_response) ? true : false;

    console.log(login_response);

    this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
    let request_data = new FormData();
    request_data.append("id", login_response.hostId);
    const body = {
      request_type: {
        request_name: "getMyTickets"
      },
      requestinfo: { "page_no": 1, "type": type }
    }

    this._MainService.postRequest(body).subscribe((res: any) => {
      this.loading.close();

      console.log(res);
      if (res.errorcode == 0) {
        this.tickets_list = res.response.result;
        this.tickets_list.forEach(x => {
          let img = x.barcode.replace("https://s3.amazonaws.com/apsession/", "https://apsession.s3.amazonaws.com/")
          this.convertImg(img).then(y => {
            x.barcode = y
          });
        })
        type == "upcoming" ? this.upcomingCount = res.response.result.length : this.oldCount = res.response.result.length;
        this.currentTicket = res.response.result[0];
        // this.dataService.getBase64ImageFromUrl("https://boookoo.s3.us-east-2.amazonaws.com/profile/1641985988.png").then(y=>{
        //   console.log(y)

        // })
        this.currentTicket.index = 0;

        // this.loading.close();
      }
      else {
        this.tickets_list = [];
        this.currentTicket = null
        // this.showError(res.status, false);
        // this.loading.close(); 
      }
    }, err => {
      console.log(err);
      this.showError("Unable to connect with server, please try again.", false);
      this.loading.close();
    });
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  changeTab(type) {
    this.type = type;
    this.getallmytickets(type);
  }

  data($event: any) {
    this.tickets = $event;
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  printDiv() {
    var printContents = document.getElementById("rightsection").innerHTML;
    var popupWin = window.open('', '_blank', 'width=500,height=500');
    // popupWin.document.open();
    popupWin.document.write('<html><head> <link href="/assets/css/loading.css" rel="stylesheet" /></head><body onload="window.print()">' + printContents + '</body></html>');
    popupWin.document.close();

    // const printContents = document.getElementById('rightsection').innerHTML;
    // const originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    // window.print();
    // document.body.innerHTML = originalContents;

  }


  mytickets() {
    this.router.navigate(['/mytickets']);
  }

  dashboard() {
    this.router.navigate(['/dashboard']);
  }

  home() {
    this.router.navigate(['/home']);
  }
  logout() {
    this.dataService.doLogOut();
    this.router.navigate(['/']);
  }

  createticket() {
    this.router.navigate(['/newevent']);
  }
  openloadingllableContent(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ticketSelectionMobile(index, item) {

    this.currentTicket = item;
    this.mobileTicketSelected = true;
    this.currentTicket.index = index;
  }

  mobileSlideTicket(isNext: boolean) {

    let ind = this.currentTicket.index;
    if (isNext == true) {
      this.currentTicket = { ...this.tickets_list[this.currentTicket.index + 1], index: 0 }
      this.currentTicket.index = ind + 1;
    }
    else {
      this.currentTicket = { ...this.tickets_list[this.currentTicket.index - 1], index: 0 }
      this.currentTicket.index = ind - 1;

    }
  }


  goBack() {
    this.mobileTicketSelected = false; this.currentTicket = null;
  }


  convertToPdf() {
    let pdf;
    html2canvas(document.querySelector('.printformClass')).then((canvas) => {
      canvas.setAttribute('crossOrigin', 'anonymous')
      pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);

      var imgData = canvas.toDataURL('image/jpeg', 1.0);
      pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);
      // pdf.addPage('image/jpeg', 'p');
      // pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);


      pdf.save(`${this.currentTicket.eventname}.pdf`);
    });
  }

  convertImg(url) {
    return this.dataService.getBase64ImageFromUrl(url).then(x => {
      console.log(x)
      return x;

    });
  }


  downloadTicket() {
    // this._TicketDownloader.ngOnInit()
    // this._TicketDownloader.downloadTickets()
    this.modalRef = this.modalService.show(TicketDownloader, { animated: true, class: "modal-xl" })
    // this.TicketDownloader.callByRef();
    // var printContents = '';
    // // printContents = this.demo.nativeElement;
    // // printContents = this.demo.nativeElement.innerHTML;
    // var popupWin = window.open('', '_blank', 'width=500,height=500');
    // // popupWin.document.open();
    // popupWin.document.write('<html><head> <link href="style.css" rel="stylesheet" /></head><body >' + printContents + '</body></html>');
    // // popupWin.document.close();
  }
}
