import { Component, OnInit, NgZone, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';
import { LoadingComponent } from '../../loading/loading.component';


@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: [ '../../../assets/css/common.css']
})
export class PayoutComponent implements OnInit {

    events: any = new Object();
  error_message: any;
  loading: any;
  bank_details: any =new Object();
  eventsPayout :any =[]

  constructor(public dataService: DataService, private router: Router, zone: NgZone, public snackBar: MatSnackBar, public dialog: MatDialog, private element: ElementRef) { }
  ngOnInit() {
    if(!this.dataService.checkIfLogged()){
      this.router.navigate(['/']);
    }
    this.events = this.dataService.getLocalStorageData("event");

    this.getpayoutdetails()
  }


  getpayoutdetails() {

    let login_response = this.dataService.getLocalStorageData("login_response");
    this.loading = this.dialog.open(LoadingComponent, { disableClose: true });

    this.dataService.getData('payouts?userId=' + login_response.hostId + '&event_id=' + this.events.id).subscribe((results: any) => {

      if (results.errorcode == 0) {
        this.bank_details= results.response.bank_details;

        this.eventsPayout = results.response.eventsPayout;
      } else {
        this.showError(results.status, false);
      }
      this.loading.close();

    }, err => {
      console.log(err);
      this.showError('Unable to connect with server, please try again.', false);
      this.loading.close();
    });
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }


  showSuccess(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-success'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }



  getcalValue(val1: any, val2: any) {

    if (!val1 || parseInt(val1) == 0) {
      return 0;
    } else if (val1 && parseInt(val1) == val2) {
      return 100;
    } else {
      return (parseInt(val1) / parseInt(val2) * 100)
    }
  }

  payoutinfo(paymentinfo){
    
    if (typeof paymentinfo.bank_name == 'undefined' || paymentinfo.bank_name.trim() == '' || paymentinfo.bank_name == null) {
      this.showError('Enter bank name!', false);
      return false;
    }
    
    else if (typeof paymentinfo.bank_account == "undefined" || paymentinfo.bank_account == '' || paymentinfo.bank_account == null) {
      this.showError('Enter bank account number', false);
      return false;
    }

    else if (typeof paymentinfo.payout_country == "undefined" || paymentinfo.payout_country == '' || paymentinfo.payout_country == null) {
      this.showError('Enter payout country', false);
      return false;
    }

    else if (typeof paymentinfo.account_type == "undefined" || paymentinfo.account_type == '' || paymentinfo.account_type == null) {
      this.showError('Enter account type', false);
      return false;
    }

    else if (typeof paymentinfo.name_on_account == "undefined" || paymentinfo.name_on_account == '' || paymentinfo.name_on_account == null) {
      this.showError('Enter name on account', false);
      return false;
    }

    else {
      let login_response = this.dataService.getLocalStorageData("login_response");

      this.loading = this.dialog.open(LoadingComponent, { disableClose: true });
      let request_data = new FormData();
      request_data.append("bank_name", paymentinfo.bank_name);
      request_data.append("account_number", paymentinfo.bank_account);
      request_data.append("payout_country", paymentinfo.payout_country);
      request_data.append("account_type", paymentinfo.account_type);
      request_data.append("name_on_account", paymentinfo.name_on_account);
      request_data.append("id_user",login_response.hostId)

      this.dataService.postData("addbank", request_data, {}).then((results: any) => {
        if (results.errorcode == 0) {       
          this.loading.close();
            this.showSuccess('Payout information added successfully!', false);
        }
        else {
          this.showError(results.status, false);
          this.loading.close();
        }
      }, err => {
        console.log(err);
        this.showError("Unable to connect with server, please try again.", false);
        this.loading.close();
      });
    }
  }
}