/* ******************************************************************************
OptimumBrew Technology Pvt. Ltd.

Title:            Get Phone TS
Since:            21 Sept, 2018
Author:           Umesh Patadiya
Email:            umeshpatadiya1995@gmail.com

****************************************************************************** */
import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog, MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-get-phone-no',
  templateUrl: './get-phone-no.component.html',
  styleUrls: ['./get-phone-no.component.css']
})
export class GetPhoneNoComponent implements OnInit {

  user_detail: any = {};
  loading: any;

  constructor(public dataService: DataService, public snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<GetPhoneNoComponent>) {
  }

  ngOnInit() {
  }

  getPhoneNumber(user_detail) {
    if (typeof user_detail.phone == "undefined" || user_detail.phone.trim() == "" || user_detail.phone == null) {
      this.showError("Please enter phone number", false);
      return false;
    }
    else if (user_detail.phone.length < 10) {
      this.showError("Please enter a valid phone number", false);
      return false;
    }
    else {
      this.dialogRef.close(user_detail);
    }
  }

  showError(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-error'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

  showSuccess(message, action) {
    let config: any = new MatSnackBarConfig();
    config.panelClass = ['snack-success'];
    config.horizontalPosition = "right";
    config.verticalPosition = "top";
    config.duration = 3000;
    this.snackBar.open(message, action ? 'Okay!' : undefined, config);
  }

}
