import { Component, OnInit, NgZone, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: [ '../../../assets/css/common.css']
})
export class ContactusComponent implements OnInit {

  constructor(public dataService: DataService, private router: Router, zone: NgZone, public snackBar: MatSnackBar, public dialog: MatDialog, private element: ElementRef) { }


  ngOnInit() {

    if(!this.dataService.checkIfLogged()){
      this.router.navigate(['/']);
    }
    
  }

}
