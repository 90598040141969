import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['../../../assets/css/common.css']
})
export class FooterComponent implements OnInit {
  year = new Date().getFullYear()
  constructor() { }

  ngOnInit() {
  }

}
