import { Component, OnInit, NgZone, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';


@Component({
  selector: 'app-app-dashboard',
  templateUrl: './app-dashboard.component.html',
  styleUrls: [ '../../assets/css/common.css']
})
export class AppDashboardComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  constructor(public dataService: DataService, private router: Router, zone: NgZone, public snackBar: MatSnackBar, public dialog: MatDialog, private element: ElementRef) { }

  ngOnInit() {

    if (!this.dataService.checkIfLogged()) {
      this.router.navigate(['/']);
    }
  }

  mytickets() {
    this.router.navigate(['/mytickets']);
  }

  dashboard() {
    this.router.navigate(['/dashboard']);
  }

  home() {
    this.router.navigate(['/home']);
  }



  logout () {
    this.dataService.doLogOut();
    this.router.navigate(['/']);
  }

  createticket(){
    this.router.navigate(['/newevent']);
  }

}
